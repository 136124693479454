import { CloseOutlined } from '@mui/icons-material'
import { message } from 'antd'

const messageInfo = (param, duration = 3) => {
  const key = Date.now()

  // 判断param是否为对象
  if (Object.prototype.toString.call(param) === '[object Object]') {
    message.open({
      content: (
        <div className='flex items-start max-w-[500px]'>
          <div>{param.content}</div>
          <CloseOutlined className='text-[20px] text-[#000] cursor-pointer' onClick={() => message.destroy(key)} />
        </div>
      ),
      key: key,
      duration: param?.duration
    })
  } else {
    message.open({
      content: (
        <div className='flex items-start max-w-[500px]'>
          <div>{param}</div>
          <CloseOutlined className='text-[20px] text-[#000] cursor-pointer' onClick={() => message.destroy(key)} />
        </div>
      ),
      key: key,
      duration: duration
    })
  }
}

export { messageInfo }
