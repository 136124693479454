// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch User Profile

export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')

  return response.data
})

// ** Fetch Chats & Contacts
export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', async () => {
  const response = await axios.get('/apps/chat/chats-and-contacts')

  return response.data
})

// ** Select Message
export const selectMessage = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
  // const response = await axios.get('/apps/chat/get-chat', {
  //   params: {
  //     id
  //   }
  // })

  const arr = []

  let find = arr.filter(item => {
    return item.id === id
  })

  await dispatch(fetchChatsContacts())

  return find[0]

  // return response.data
})

export const appChatSlice = createSlice({
  name: 'skype',
  initialState: {
    chats: null,
    contacts: null,
    userProfile: {
      id: 11,
      avatar: '',
      fullName: '',
      role: '',
      about: '',
      status: '',
      settings: {
        isTwoStepAuthVerificationEnabled: true,
        isNotificationsOn: false
      }
    },
    selectedMessage: {
      id: 1,
      chat: {
        chat: [
          {
            text: '',
            senderId: 11,
            date: '',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: '', avatar: '' }
      }
    },
    list: []
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload
    })
    builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
      state.contacts = action.payload.contacts
      state.chats = action.payload.chatsContacts
    })
    builder.addCase(selectMessage.fulfilled, (state, action) => {
      state.selectedMessage = action.payload
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
