// ** MUI Imports
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

import { useState } from 'react'

import Image from 'next/image'

import styles from './ModeTogglerCss.module.scss'
import { useTranslation } from 'next-i18next'

const ModeToggler = props => {
  // ** Props
  const { settings, saveSettings } = props

  const handleModeChange = mode => {
    saveSettings({ ...settings, mode: mode })
  }

  const handleModeToggle = () => {
    if (settings.mode === 'light') {
      handleModeChange('dark')
    } else {
      handleModeChange('light')
    }
  }

  const [isHovered, setIsHovered] = useState(false)
  const { t } = useTranslation('common')

  return (
    <div
      className={`py-1 flex items-center hover:text-[#12D8AB] text-[#4C4C4C] ${styles.active}`}
      onMouseEnter={() => setIsHovered('night-view')}
      onMouseLeave={() => setIsHovered(false)}
    >
      <IconButton color='inherit' aria-haspopup='true' onClick={handleModeToggle}>
        <div
          className={`text-opacity-70 font-[600] hover:text-opacity-80 hover:text-[#12D8AB] hover:text-[#12D8AB] flex items-center group `}
        >
          <Image
            src={isHovered == 'night-view' ? '/images/common/night-view-active.svg' : '/images/common/night-view.svg'}
            width='18'
            height='18'
            alt='img'
            style={{ marginLeft: '4px', marginRight: '6px' }}
          />
          <p className={`text-[14px] font-[600] ${styles.color}`}>{t('Primary Color')}</p>
        </div>
      </IconButton>
    </div>
  )
}

export default ModeToggler
