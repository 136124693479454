// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import conversionUtcDate from 'src/utils/conversionUtcDate'
import { getAlbumDetail, getAlbumSummary, getGcloudAll, getLocalAndroidAll } from 'src/@api/dashboards'
import { offset } from '@popperjs/core'

// 所有相册文件件查询信息
let foldersInfos = []

// 当前偏移量
let nowOffset = 0

// 当前文件夹名称
let fileRid = ''

// 目录级别
let level = 0

// 当前文件夹名称
let nowName = ''

// 展示数据
let selectedData = []

// 全部数据
let allData = []

// 备份图片
let useImages = []

// 格式化时间
const formateSeconds = endTime => {
  let secondTime = parseInt(endTime) //将传入的秒的值转化为Number
  let min = 0 // 初始化分
  let h = 0 // 初始化小时
  let result = ''
  if (secondTime > 60) {
    min = parseInt(secondTime / 60)
    secondTime = parseInt(secondTime % 60)
    if (min > 60) {
      h = parseInt(min / 60)
      min = parseInt(min % 60)
    }
  }
  result = `${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`

  return result
}

// 追加数据（相同的追加在同数组，不同相册追加新对象）
const appendChildData = (oldData, row, result) => {
  const newData = []

  if (oldData.length === 0) {
    newData.push({ name: row.album || row.name, list: [...result] })
  } else {
    oldData.forEach(item => {
      if (item.name === row.album || item.name === row.name)
        newData.push({ name: item.name, list: [...item.list, ...result] })
      else newData.push(item)
    })

    const flag = newData.some(item => item.name === row.album || item.name === row.name)

    if (!flag) newData.push({ name: row.album || row.name, list: [...result] })
  }

  return newData
}

// 请求数据查询
export const fetchPhotoData = createAsyncThunk(
  'fetchPhotoData',
  async ({ type, row, reqNum, getDeviceUser, userData }) => {
    console.log('fetchPhotoData::::')

    // console.log('type:::', type)
    // console.log('row:::', row)
    // console.log('reqNum:::', reqNum)
    // console.log('getDeviceUser:::', getDeviceUser)
    // console.log('userData:::', userData)

    // 清空当前偏移量
    nowOffset = 0

    // 存储当前点击文件夹查询信息
    if (foldersInfos.length === 0) foldersInfos.push({ name: row.album || row.name, offset: 0 })
    else {
      const newOffset = []

      foldersInfos.forEach(item => {
        if (item.name === row.album || item.name === row.name) {
          nowOffset = Number(item.offset) + Number(reqNum)

          newOffset.push({ ...item, offset: nowOffset })
        } else newOffset.push(item)
      })

      const flag = newOffset.some(item => item.name === row.album || item.name === row.name)

      if (!flag) newOffset.push({ name: row.album || row.name, offset: 0 })

      foldersInfos = [...newOffset]
    }

    // console.log('nowOffset:::', nowOffset)

    const now = foldersInfos.find(item => item.name === row.album || item.name === row.name)
    const maxNum = now !== undefined ? now.maxNum : undefined

    if (maxNum !== undefined) {
      // 设置现在名称
      nowName = row.album || row.name

      return {
        foldersInfos,
        fileRid,
        level,
        selectedData,
        allData,
        useImages,
        nowName
      }
    }

    let response
    if (type === 'android-local') {
      fileRid = row.name
      response = await getLocalAndroidAll({
        username: userData.email,
        params: {
          category: 'ALBUM_DETAIL',
          deviceUdid: getDeviceUser,
          offset: nowOffset,
          limit: reqNum,
          rid: row.name
        }
      })
    } else if (type === 'google-cloud') {
      fileRid = row.name
      response = await getGcloudAll({
        username: getDeviceUser,
        params: {
          category: 'ALBUM_DETAIL',
          offset: nowOffset,
          limit: reqNum,
          rid: row.id
        }
      })
    } else {
      fileRid = row.album
      response = await getAlbumDetail({
        username: getDeviceUser,
        params: {
          category: 'ALBUM_DETAIL',
          offset: nowOffset,
          limit: reqNum,
          rid: row.album
        }
      })
    }

    console.log('response:::', response)

    // 设置现在名称
    nowName = row.album || row.name

    if (response.data.contents === null) {
      const name = row.album || row.name

      foldersInfos.forEach((item, index) => {
        if (item.name === name) foldersInfos[index] = { ...item, maxNum: true }
      })

      level = 1

      // 追加空数组
      selectedData = appendChildData(selectedData, row, [])
      allData = selectedData

      // console.log('foldersInfos:::', foldersInfos)
      // console.log('fileRid:::', fileRid)
      // console.log('level:::', level)
      // console.log('selectedData:::', selectedData)
      // console.log('allData:::', allData)
      // console.log('useImages:::', useImages)

      return {
        foldersInfos,
        fileRid,
        level,
        selectedData,
        allData,
        useImages,
        nowName
      }
    }

    let result = response.data.contents.map(item => {
      if (type === 'android-local') {
        if (item.type == 'mp4' || item.type == 'mov' || item.type == 'video') {
          return {
            ...item,
            original_url: item.original.url,
            width: 255,
            type: item.type == 'mp4' || item.type == 'mov' || item.type == 'video' ? 'mp4' : item.type,
            cover_url: item.type == 'mp4' || item.type == 'mov' || item.type == 'video' ? item.cover.url : '',
            cover_duration:
              item.type == 'mp4' || item.type == 'mov' || item.type == 'video'
                ? formateSeconds(item.cover.duration)
                : '',
            play: false
          }
        } else {
          return {
            ...item,
            original_url: item.original.url,
            medium_url: item.medium.url,
            thumb_url: item.thumb.url,
            width: item.thumb.width || 255,
            type: item.type == 'mp4' || item.type == 'mov' || item.type == 'video' ? 'mp4' : item.type,
            cover_url: item.type == 'mp4' || item.type == 'mov' || item.type == 'video' ? item.cover.url : '',
            cover_duration:
              item.type == 'mp4' || item.type == 'mov' || item.type == 'video'
                ? formateSeconds(item.cover.duration)
                : '',
            play: false
          }
        }
      } else {
        return {
          ...item,
          original_url: item.original.url,
          medium_url: item.medium.url,
          thumb_url: item.thumb.url,
          width: item.thumb.width,
          type:
            item.thumb.type == 'mp4' || item.thumb.type == 'mov' || item.thumb.type == 'video'
              ? 'mp4'
              : item.thumb.type,
          cover_url:
            item.thumb.type == 'mp4' || item.thumb.type == 'mov' || item.thumb.type == 'video' ? item.cover.url : '',
          cover_duration:
            item.thumb.type == 'mp4' || item.thumb.type == 'mov' || item.thumb.type == 'video'
              ? formateSeconds(item.cover.duration)
              : ''
        }
      }
    })

    level = 1

    // 存储当前选择的图片信息
    selectedData = appendChildData(selectedData, row, result)
    allData = selectedData

    // 图片独立存储一份
    let images = result.filter(item => {
      return item.type != 'mp4'
    })

    useImages = appendChildData(useImages, row, images)

    // 判断是否查询完全部
    const nowData = allData.find(item => item.name === row.album || item.name === row.name)
    let totalNum = nowData.list.length

    console.log('row.amount:::', row.amount)
    console.log('totalNum:::', totalNum)

    if (Number(totalNum) === Number(row.amount)) {
      foldersInfos.forEach((item, index) => {
        if (item.name === nowData.name) foldersInfos[index] = { ...item, maxNum: true }
      })
    }

    // console.log('foldersInfos:::', foldersInfos)
    // console.log('fileRid:::', fileRid)
    // console.log('level:::', level)
    // console.log('selectedData:::', selectedData)
    // console.log('allData:::', allData)
    // console.log('useImages:::', useImages)

    return {
      foldersInfos,
      fileRid,
      level,
      selectedData,
      allData,
      useImages,
      nowName
    }
  }
)

// 重置文件夹内容
export const resetNowName = createAsyncThunk('resetNowName', async ({ fileRid, level, nowName }) => {
  console.log('resetNowName::::')

  return {
    fileRid,
    level,
    nowName
  }
})

export const appChatSlice = createSlice({
  name: 'Photo Data',
  initialState: {
    foldersInfos: [],
    fileRid: '',
    level: 0,
    selectedData: [],
    allData: [],
    useImages: [],
    nowName,
    isLoading: false // 本次请求状态
  },
  reducers: {
    removeNowName: state => {
      console.log('removeNowName::::')
      state.level = 0
      state.nowName = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchPhotoData.pending, state => {
      state.isLoading = true // 请求开始
    })
    builder.addCase(fetchPhotoData.fulfilled, (state, action) => {
      state.foldersInfos = [...action.payload.foldersInfos]
      state.fileRid = action.payload.fileRid
      state.level = action.payload.level
      state.selectedData = [...action.payload.selectedData]
      state.allData = [...action.payload.allData]
      state.useImages = [...action.payload.useImages]
      state.nowName = action.payload.nowName

      state.isLoading = false // 请求结束
    })
    builder.addCase(fetchPhotoData.rejected, state => {
      state.isLoading = false // 请求结束
    })
    builder.addCase(resetNowName.fulfilled, (state, action) => {
      state.fileRid = action.payload.fileRid
      state.level = action.payload.level
      state.nowName = action.payload.nowName
    })
  }
})

export const { removeNowName } = appChatSlice.actions

export default appChatSlice.reducer
