// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch User Profile

export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')

  return response.data
})

// ** Fetch Chats & Contacts
export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', async () => {
  const response = await axios.get('/apps/chat/chats-and-contacts')

  return response.data
})

// ** Select Message
export const selectMessage = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
  // const response = await axios.get('/apps/chat/get-chat', {
  //   params: {
  //     id
  //   }
  // })

  const arr = [
    {
      id: 1,
      chat: {
        chat: [
          {
            text: 'You often laugh. You seem really happy!',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:01:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yes, you are right. I have been admitted by a famous university. I will go there in September.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:01:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Congratulations!👍',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:03:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Thank you. Keeping a good mood and staying active really helped me a lot while I was preparing for the college entrance examination.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:05:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I agree with you.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:05:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Aiden',label:'nice day', avatar: '/images/avatars/1.png' }
      }
    },
    {
      id: 2,
      chat: {
        chat: [
          {
            text: 'Oh, you look nervous. What’s wrong with you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:13:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I am so anxious that I can hardly keep calm.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:13:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: ' What has happened?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:16:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I am going to attend an important interview this afternoon. I care very much about it. You know, I’ve been looking for a job for days and I haven’t found one yet.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:20:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Oh, take it easy. Nervousness results in failure. I think you are a talented person.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Really?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Of course. Please be more confident, and you will do much better this time. Confidence is always the key to success.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Thank you very much. Now, I am cheering up. I’ll do my best to succeed in this interview.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Jacob',label:'Iron Man', avatar: '/images/avatars/2.png' }
      }
    },
    {
      id: 3,
      chat: {
        chat: [
          {
            text: 'Which is your preference, tea or coffee?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I must say I have a strong preference for tea.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'It When did you start drinking tea.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:47:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Last year when I visited China.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:47:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Ethan',label:'beautiful girl', avatar: '/images/avatars/3.png' }
      }
    },
    {
      id: 4,
      chat: {
        chat: [
          {
            text: 'We’re going to need someone to replace Mike after he retires, you know.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'What do you think of Larry? He‘s a go-getter.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Hmmm, he is a good choice. But let me warn you, Frank seems to think he’s in line for Mike‘s position. He will probably be very upset.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:56:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yeah, I thought about that too. That may be a problem...',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:57:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Caden',label:'new departure', avatar: '/images/avatars/4.png' }
      }
    },
    {
      id: 5,
      chat: {
        chat: [
          {
            text: 'I want to do some shopping. Could you help me?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Of course, I’d like to. What are you going to buy?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I want to buy some souvenirs for my family and friends.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Well , I‘d suggest the New World Department Store. It’s not too far.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'OK. Let‘s go.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Jackson',label:'single af', avatar: '/images/avatars/5.png' }
      }
    },
    {
      id: 6,
      chat: {
        chat: [
          {
            text: 'How are you It’s been ages! What are you doing these days',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I just opened my own restaurant. I’m also head chef.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'You’re kidding! You’ve always hated cooking!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Well, I used to hate cooking, but now I love it.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'When did you change your mind?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'After I tried French food. Before that,I used to cook really boring things for my family.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:01:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Smith',label:'The continuation of Einstein', avatar: '/images/avatars/6.png' }
      }
    },
    {
      id: 7,
      chat: {
        chat: [
          {
            text: 'How are you today ，Mr Brown?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I‘m quite well ，thank you.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'So, what do you think How do you like New York?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I’m having a great time. I love it. I’m glad we came',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yeah. I really like the stores and the shopping.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I love the museums, too. But the traffic is pretty bad.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yeah. I hate all this traffic. It’s really noisy.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Johny',label:'Who says women are inferior to men', avatar: '/images/avatars/7.png' }
      }
    },
    {
      id: 8,
      chat: {
        chat: [
          {
            text: 'Do you like China?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yes，I like it very much',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Are you used to Chinese food?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yes,I am.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Which dish do you particularly like?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I like them all, except for Sichuan dishes because they are too hot.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Traditional Chinese culture is profound.Do you know them?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Sorry, I don’t know much about Chinese culture.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'What‘s your impression of Chinese people?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'They are very friendly.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Which country do you like best, and why?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'France, because people there are very romantic.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Sammy',label:'One international model', avatar: '/images/avatars/8.png' }
      }
    },
    {
      id: 9,
      chat: {
        chat: [
          {
            text: 'What’s the weather like today? Is it cold?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'No. It‘s warm and sunny.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Let’s go swimming.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text:  'I like playing football.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'OK. Let’s play football.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Cameron',label:'No one I cant save', avatar: '/images/avatars/4.png' }
      }
    },
    {
      id: 10,
      chat: {
        chat: [
          {
            text: 'Can you tell the result?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'It‘s love all.Now the score is in our favor.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Can you tell which team seems to win?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Hard to say.Now the score is in our favor.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:25:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yes.Our guest team is really tough.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:25:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'They’re still fighting hard.Let‘s wait and see.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:25:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'James',label:'Poverty does not bind me', avatar: '/images/avatars/4.png' }
      }
    },
    {
      id: 11,
      chat: {
        chat: [
          {
            text: 'Hi Tom, how are you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I’m fine, thanks. How are you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Sarah',label:'Yearning for freedom', avatar: '/images/avatars/2.png' }
      }
    },
    {
      id: 12,
      chat: {
        chat: [
          {
            text: 'Have you heard that John has fallen from the tall oak tree in the backyard?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'God! Is it serious?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Believe it or not, he is as strong as a horse and he has nothing hurt.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Are you kidding?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'No kidding! I saw it with my own eyes.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Amy',label:'Not willing to be present', avatar: '/images/avatars/1.png' }
      }
    }
  ]

  let find = arr.filter(item => {
    return item.id === id
  })

  await dispatch(fetchChatsContacts())

  return find[0]

  // return response.data
})

export const appChatSlice = createSlice({
  name: 'WhatsApp',
  initialState: {
    chats: null,
    contacts: null,
    userProfile: {
      id: 11,
      avatar: '/images/Call-log/1.webp',
      fullName: 'John Doe',
      role: 'admin',
      about:
        'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
      status: 'online',
      settings: {
        isTwoStepAuthVerificationEnabled: true,
        isNotificationsOn: false
      }
    },
    selectedMessage: {
      id: 1,
      chat: {
        chat: [
          {
            text: 'You often laugh. You seem really happy!',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:01:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yes, you are right. I have been admitted by a famous university. I will go there in September.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:01:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Congratulations!👍',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:03:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Thank you. Keeping a good mood and staying active really helped me a lot while I was preparing for the college entrance examination.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:05:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I agree with you.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:05:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Aiden', avatar: '/images/avatars/1.png' }
      }
    },
    list: [
      { id: 1, name: 'Aiden', avatres: '/images/avatars/1.png',chat:'I agree with you.',time:'4:15 PM'},
      { id: 2, name: 'Jacob', avatres: '/images/avatars/2.png',chat:'Thank you very much. Now, I am cheering up. I’ll do my best to succeed in this interview.',time:'4:22 PM' },
      { id: 3, name: 'Ethan', avatres: '/images/avatars/3.png',chat:'Last year when I visited China.',time:'4:45 PM' },
      { id: 4, name: 'Caden', avatres: '/images/avatars/4.png',chat:'Yeah, I thought about that too. That may be a problem...',time:'4:45 PM' },
      { id: 5, name: 'Jackson', avatres: '/images/avatars/5.png',chat:'OK. Let‘s go.',time:'4:55 PM' },
      { id: 6, name: 'Smith', avatres: '/images/avatars/6.png',chat:'After I tried French food. Before that,I used to cook really boring things for my family.',time:'5:01 PM' },
      { id: 7, name: 'Johny', avatres: '/images/avatars/7.png',chat:'Yeah. I hate all this traffic. It’s really noisy.',time:'5:12 PM' },
      { id: 8, name: 'Sammy', avatres: '/images/avatars/8.png',chat:'France, because people there are very romantic.',time:'5:45 PM' },
      { id: 9, name: 'Cameron', avatres: '/images/avatars/4.png',chat:'OK. Let’s play football.',time:'4:45 PM' },
      { id: 10, name: 'James', avatres: '/images/avatars/4.png',chat:'They‘re still fighting hard.Let’s wait and see.',time:'4:45 PM' },
      { id: 11, name: 'Sarah', avatres: '/images/avatars/2.png',chat:'I’m fine, thanks. How are you?',time:'5:45 PM' },
      { id: 12, name: 'Amy', avatres:'/images/avatars/1.png',chat:'No kidding! I saw it with my own eyes.',time:'5:45 PM' }
    ]
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload
    })
    builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
      state.contacts = action.payload.contacts
      state.chats = action.payload.chatsContacts
    })
    builder.addCase(selectMessage.fulfilled, (state, action) => {
      state.selectedMessage = action.payload
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
