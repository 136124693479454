// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch User Profile

export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')

  return response.data
})

// ** Fetch Chats & Contacts
export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', async () => {
  const response = await axios.get('/apps/chat/chats-and-contacts')

  return response.data
})

// ** Select Message
export const selectMessage = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
  // const response = await axios.get('/apps/chat/get-chat', {
  //   params: {
  //     id
  //   }
  // })

  const arr = [
    {
      id: 1,
      chat: {
        chat: [
          {
            text: 'Hello,Nice to meet you',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Can you send some photos?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Sure, I not very good looking',
            senderId: 1,
            img: '/images/tinder/1.webp',
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },{
            text: 'Sure, I not very good looking',
            senderId: 1,
            img: '/images/tinder/2.webp',
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Sure, I not very good looking',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Very pretty.!😍',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Is it convenient to come out for coffee',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Sure, but I not free this week',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
        ],
        contact: { id: 1, fullName: 'DianeDong',label:'nice day', avatar: '/images/tinder/1.jpg' }
      }
    },
    {
      id: 2,
      chat: {
        chat: [
          {
            text: 'Hello,beauty',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:13:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Excuse me, are you a model',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:13:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yes, I send you two more photos',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:16:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'We need to check if we can provide you such information.            ',
            senderId: 11,
            img: '/images/tinder/3.webp',
            date: 'Mon Dec 10 2018 08:20:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I will inform you as I get update on this..            ',
            senderId: 11,
            img: '/images/tinder/4.webp',
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Are the photos good?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Very good, as if I saw my goddess',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'CatherineLuo',label:'Iron Man', avatar: '/images/Call-log/3.webp' }
      }
    },
    {
      id: 3,
      chat: {
        chat: [
          {
            text: 'What s up?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Nothing much. What s new with you?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Not too much. I ve been pretty busy the whole day.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },{
            text: 'Me too. It seems like all I do is eating and sleep. I m browned off with this routine life.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'You remember that thing you told me last week?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Which one? The one about the English salon?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yeah. That s the one. Listen, I ve got a terrific idea about the whole project and we need to discuss it.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I m sorry, but I m really not terribly interested in what you said.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
        ],
        contact: { id: 1, fullName: 'JasonZhou',label:'beautiful girl', avatar: '/images/Call-log/2.webp' }
      }
    },
    {
      id: 4,
      chat: {
        chat: [
          {
            text: 'Did you watch the football match this morning?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Of course.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Can you guess which team will win?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:56:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'The home team will win for sure.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:57:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'SusanWang',label:'new departure', avatar: '/images/Call-log/4.webp' }
      }
    },
    {
      id: 5,
      chat: {
        chat: [
          {
            text: 'You re new here，aren t you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yes，and I can t type fast.Sorry to keep you waiting.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'It doesn t matter.You will do a good job soon.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'It s a lovely day，isn t it?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yes，it is.Are you from Australia?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'No，I m from the USA.I m Bill White.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'My name is Liu Ying.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Glad to see you!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Glad to see you，too! Are you studying?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yes，I m studying Chinese.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Do you think it is easy or difficult?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Oh，it s very difficult.But it s really interesting and I like it very much.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Hope you make rapid progress in your Chinese learning.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Thank you!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'ZoeSharonLi',label:'single af', avatar: '/images/Call-log/5.webp' }
      }
    },
    {
      id: 6,
      chat: {
        chat: [
          {
            text: 'Hello! How are you today?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I feel very nervous. I’ve just had a test and I’m anxious about it.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'There is no use worrying about it now. You can do nothing about it. Forget it.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'That’s true. I really should go home and prepare for the next test, but I feel tired.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Let’s go and get a coffee together. That will help you relax!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Ok. I feel like sitting down and having a chat. How are you getting on?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:01:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Oh, I’m always happy! If I’m depressed, I’ll call some friends to have a chat.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:01:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'That’s a good idea. But I usually stay at home and feel bored.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:01:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I hate spending too much time at home. I enjoy going out to parties or other activities.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:01:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Perhaps I often worry too much.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:01:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Smith',label:'The continuation of Einstein', avatar: '/images/tinder/6.jpg' }
      }
    },
    {
      id: 7,
      chat: {
        chat: [
          {
            text: 'Oh, you look nervous. What’s wrong with you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I am so anxious that I can hardly keep calm.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'What has happened?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I am going to attend an important interview this afternoon. I care very much about it.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'You know, I’ve been looking for a job for days and I haven’t found one yet.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Oh, take it easy. Nervousness results in failure. I think you are a talented person.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Really?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Of course. Please be more confident, and you will do much better this time. Confidence is always the key to success.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Thank you very much. Now, I am cheering up. I’ll do my best to succeed in this interview.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'HelenGuan',label:'Who says women are inferior to men', avatar: '/images/Call-log/7.webp' }
      }
    },
    {
      id: 8,
      chat: {
        chat: [
          {
            text: 'Wow, how cute you look today!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Thanks.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Who bought you this beautiful skirt?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'It s my boyfriend. OK, let s get down to business. Could you tell me something new about Mrs. Elizabeth?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'No problem',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Sammy',label:'One international model', avatar: '/images/Call-log/8.webp' }
      }
    },
    {
      id: 9,
      chat: {
        chat: [
          {
            text: 'I need some new clothes for the coming season. Where shall I go to pick up some?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Im thinking of buying some stuff, too. Lets go to the speciality stores. There will be some new models for sale now.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Will the new models be very expensive?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Depends on the brands. But there will be some out-of-season clothes on discount, too. So maybe we can also get some stuff, good and cheap.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Great. Lets go.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'MayWang',label:'No one I cant save', avatar: '/images/Call-log/9.webp' }
      }
    },
    {
      id: 10,
      chat: {
        chat: [
          {
            text: 'Excuse me, can you tell me where I can go fishing?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I like fishing too. I usually fish near the lake.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I want to go fishing today, but I have left my rod and fishing line at home.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'What a shame.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:25:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'James',label:'Poverty does not bind me', avatar: '/images/Call-log/10.webp' }
      }
    },
    {
      id: 11,
      chat: {
        chat: [
          {
            text: 'May I fix an appointment wtth Dr.Cui?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Sure.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'When will it be convenient for her?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Any time is all right, except Sunday.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'How about ten oclock tomorrowv?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'OK. Ill tell her.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Rebecca',label:'Yearning for freedom', avatar: '/images/Call-log/11.webp' }
      }
    },
    {
      id: 12,
      chat: {
        chat: [
          {
            text: 'Hi, Tony. You look unhappy. What’s wrong?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Oh, Mary. I made a big mistake.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'What happened?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I really wish I hadn’t done it.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'What on earth? are you talking about?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I got caught cheating. I feel so ashamed. The teacher saw me and told me I failed.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'What were you thinking?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'You know my father. If I fail, he’ll kill me. I have to do well.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Amy',label:'Not willing to be present', avatar: '/images/Call-log/12.webp' }
      }
    }
  ]

  let find = arr.filter(item => {
    return item.id === id
  })

  await dispatch(fetchChatsContacts())

  return find[0]

  // return response.data
})

export const appChatSlice = createSlice({
  name: 'Telegram',
  initialState: {
    chats: null,
    contacts: null,
    userProfile: {
      id: 11,
      avatar: '/images/tinder/1.jpg',
      fullName: 'John Doe',
      role: 'admin',
      about:
        'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
      status: 'online',
      settings: {
        isTwoStepAuthVerificationEnabled: true,
        isNotificationsOn: false
      }
    },
    selectedMessage: {
      id: 1,
      chat: {
        chat: [
          {
            text: 'Hello,Nice to meet you',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Can you send some photos?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Sure, I not very good looking',
            senderId: 1,
            img: '/images/tinder/1.webp',
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },{
            text: 'Sure, I not very good looking',
            senderId: 1,
            img: '/images/tinder/2.webp',
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Sure, I not very good looking',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Very pretty.!😍',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Is it convenient to come out for coffee',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Sure, but I not free this week',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
        ],
        contact: { id: 1, fullName: 'DianeDong', avatar: '/images/tinder/1.jpg' }
      }
    },
    list: [
      { id: 1, name: 'DianeDong', avatres: '/images/tinder/1.jpg',chat:'Sure, but I not free this week',time:'4:15 PM'},
      { id: 2, name: 'CatherineLuo', avatres: '/images/Call-log/3.webp',chat:'Very good, as if I saw my goddess',time:'4:22 PM' },
      { id: 3, name: 'JasonZhou', avatres: '/images/Call-log/2.webp',chat:'I m sorry, but I m really not terribly interested in what you said.',time:'4:45 PM' },
      { id: 4, name: 'SusanWang', avatres: '/images/Call-log/4.webp',chat:'The home team will win for sure.',time:'4:45 PM' },
      { id: 5, name: 'ZoeSharonLi', avatres: '/images/Call-log/5.webp',chat:'Thank you!',time:'4:55 PM' },
      { id: 6, name: 'Smith', avatres: '/images/tinder/6.jpg',chat:'Perhaps I often worry too much.',time:'5:01 PM' },
      { id: 7, name: 'HelenGuan', avatres: '/images/Call-log/7.webp',chat:'Thank you very much. Now, I am cheering up. I’ll do my best to succeed in this interview.',time:'5:12 PM' },
      { id: 8, name: 'Sammy', avatres: '/images/Call-log/8.webp',chat:'No problem',time:'5:45 PM' },
      { id: 9, name: 'MayWang', avatres: '/images/Call-log/9.webp',chat:'Great. Lets go.',time:'4:45 PM' },
      { id: 10, name: 'James', avatres: '/images/Call-log/10.webp',chat:'What a shame.',time:'4:45 PM' },
      { id: 11, name: 'Rebecca', avatres: '/images/Call-log/11.webp',chat:'OK. Ill tell her.',time:'5:45 PM' },
      { id: 12, name: 'Amy', avatres:'/images/Call-log/12.webp',chat:'You know my father. If I fail, he’ll kill me. I have to do well.',time:'5:45 PM' }
    ]
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload
    })
    builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
      state.contacts = action.payload.contacts
      state.chats = action.payload.chatsContacts
    })
    builder.addCase(selectMessage.fulfilled, (state, action) => {
      state.selectedMessage = action.payload
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
