// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Fetch User Profile

export const fetchUserProfile = createAsyncThunk('appChat/fetchUserProfile', async () => {
  const response = await axios.get('/apps/chat/users/profile-user')

  return response.data
})

// ** Fetch Chats & Contacts
export const fetchChatsContacts = createAsyncThunk('appChat/fetchChatsContacts', async () => {
  const response = await axios.get('/apps/chat/chats-and-contacts')

  return response.data
})

// ** Select Message
export const selectMessage = createAsyncThunk('appChat/selectChat', async (id, { dispatch }) => {
  // const response = await axios.get('/apps/chat/get-chat', {
  //   params: {
  //     id
  //   }
  // })

  const arr = [
    {
      id: 1,
      chat: {
        chat: [
          {
            text: 'Do you believe in UFOs?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: '1',
            senderId: 1,
            img: '/images/facebook/1.jpg',
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Sure, a UFO is just species going from other planets. If you think about it, our space shuttles are UFOs.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I’ve never thought about like that before.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I’ve seen photos of UFO in America before, but they’ve all of darked up.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I think there must be.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Why is that?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I don’t think it will make sense if we are the only being in this whole world. The world is just far to big for that.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Do you think there’s intelligent life trying to contact us?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'No, but we are trying to contact them.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Do you really think global warming will destroy earth someday?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'There’s a lot of evidence suggests it will. But I don’t think it’s gonna happen anytime soon.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Aiden',label:'nice day', avatar: '/images/avatars/1.png' }
      }
    },
    {
      id: 2,
      chat: {
        chat: [
          {
            text: 'How many planets are there in the Solar System?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:13:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'That’s easy. Everyone knows there are nine.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:13:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'NOt anymore. Can you believe it? They’ve decided that Pluto is not a planet anymore.🤔',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:16:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Nice try. I wasn’t born yesterday you know.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:20:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I am dead serious. They’ve decided that it’s too small to be a planet. But actually they haven’t yet agreed on how big something has to be in order to be a planet anymore.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'That sounds crazy. But they can’t just change their minds about things like that.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yes, they can. If you remember correctly people used to believe the world was flat.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Do you that oneday we are able to travel to another planet for a vacation?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I suppose we could actually live on a planet outside the Milky Way.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Do you think we will get to meet Elites from outer space?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I hope not. I think it would be a threat to those of us that live in the earth.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:22:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Jacob',label:'Iron Man', avatar: '/images/avatars/2.png' }
      }
    },
    {
      id: 3,
      chat: {
        chat: [
          {
            text: 'Do you have any plans for tomorrow?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Well, I was going to drive to Birmingham to see some friends.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I just have to go to class tomorrow afternoon.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:47:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'If you are lucky, it will get canceled, and you can have a snow day.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:47:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'if that happens, then I’ll invite you all here, and we can drink hot coco, and watch movies.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:50:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'That sounds like a fun.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Hey, look outside, it’s starting to drizzle.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:00:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'English weather is even more predictable than I had previously thought.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Ethan',label:'beautiful girl', avatar: '/images/avatars/3.png' }
      }
    },
    {
      id: 4,
      chat: {
        chat: [
          {
            text: 'Are you ready to go to the supermarket?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Yes, are you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I’ve got my money and my keys. So I’m ready.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:56:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'You do know that it’s absolutely freezing out, don’t you? I will put a coat on, if I were you.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:57:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'It wasn’t cold out this morning. Well, it started raining since then. Look out the window, it’s pouring rain out there.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Oh, I guess we’d better grab our umbrella then as well.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Have a look at outside for me. Does that look like a hell to you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'It does actually. Maybe we should wait for it clear up before we go shopping.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },,
          {
            text: 'That’s a good idea. But maybe we should move the car, so that the hail doesn’t dent it.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },,
          {
            text: 'Ok, I’ll go move the car if you turn the heat on. It’s a bit chilly here.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
        ],
        contact: { id: 1, fullName: 'Caden',label:'new departure', avatar: '/images/avatars/4.png' }
      }
    },
    {
      id: 5,
      chat: {
        chat: [
          {
            text: 'Hi,Jone',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Hi, Jackson. How are you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I fine, thank you. And you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I fine, too, thank you',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:55:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Jackson',label:'single af', avatar: '/images/avatars/5.png' }
      }
    },
    {
      id: 6,
      chat: {
        chat: [
          {
            text: 'Hi,Jone!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Hi Smith.Nice to meet you again',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I hope you are Settling in at school!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I am! I made lots of fricnds and I really enjoy my courscs.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Good! Me too! By the way.you didn tell me what you are studying here.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Didnt I? I doing business studies.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:01:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Smith',label:'The continuation of Einstein', avatar: '/images/avatars/6.png' }
      }
    },
    {
      id: 7,
      chat: {
        chat: [
          {
            text: 'I really admire you.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'why? Your words just came out of the blue.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'you are really happy and able to achieve high scores at school.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I work very hard and that is it you know practice makes perfect.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'isn it there anything else?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I have no idea.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'you must have a high IQ score.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'let me tell you a secret.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'go ahead.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'my IQ score is slightly above average.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'seriously? But you are such a genius.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'maybe I ve got a higher EQ.Im not sure.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'well, perhaps you re right.Sometimes EQ matters more than IQ.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:12:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'Johny',label:'Who says women are inferior to men', avatar: '/images/avatars/7.png' }
      }
    },
    {
      id: 8,
      chat: {
        chat: [
          {
            text: 'Do you have a bf?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Yes，I had a bf before.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'We parted from each other last month.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Have you got a new ones',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Emmmm，no. How about you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I am single，I have no real gf,only an E-gf,that is you.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Haha，so you are my E-bf.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I like you. Do you agree to be my gf?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Emmmm. .. OK,let is try to be.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Wow! Blablablabla',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Hey! What is it?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I am flying like a bird',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Sammy',label:'One international model', avatar: '/images/avatars/8.png' }
      }
    },
    {
      id: 9,
      chat: {
        chat: [
          {
            text: 'I hope you are Settling in at school!',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I am! I ve made lots of fricnds and I really enjoy my courscs.',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:21:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Good! Me too! By the way.you didnt tell me what you are studying here.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Didnt I? Im doing business studies.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Rcay? That is great.You bope tojoin a pany after graduating?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },{
            text: 'I suppoes I might get a job in Toronto when I graduate.It is too soon to say really。',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'What about your friend Zoe.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'She is from Australia.She lives in Sydney Her fat runs a hotel business there and she is doing a detyee in accountancy.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Accountancy? Really? She must bc vcry good at figurcsl.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I guess so.Wbat about you Simon?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Im studying law.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Great! So I know who to call ifl evcr need a lawyer.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Surt wele anytime',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
        ],
        contact: { id: 1, fullName: 'Cameron',label:'No one I cant save', avatar: '/images/avatars/4.png' }
      }
    },
    {
      id: 10,
      chat: {
        chat: [
          {
            text: 'Hello, when will you send the requirements?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'Hi, I will be send you the requirements by tomorrow',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Hello Can you send me the details?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Hello..sure',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:25:00 GMT+0000 (GMT)',
            isFromMe: true
          }
        ],
        contact: { id: 1, fullName: 'James',label:'Poverty does not bind me', avatar: '/images/avatars/4.png' }
      }
    },
    {
      id: 11,
      chat: {
        chat: [
          {
            text: 'Hi Tom, how are you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I’m fine, thanks. How are you?',
            senderId: 11,
            date: 'Mon Dec 10 2018 09:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Sarah',label:'Yearning for freedom', avatar: '/images/avatars/2.png' }
      }
    },
    {
      id: 12,
      chat: {
        chat: [
          {
            text: 'The weather is lovely, isn t it',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Hi, I will be send you the requirements by tomorrow.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:46:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Amy',label:'Not willing to be present', avatar: '/images/avatars/1.png' }
      }
    }
  ]

  let find = arr.filter(item => {
    return item.id === id
  })

  await dispatch(fetchChatsContacts())

  return find[0]

  // return response.data
})

export const appChatSlice = createSlice({
  name: 'WhatsApp',
  initialState: {
    chats: null,
    contacts: null,
    userProfile: {
      id: 11,
      avatar: '/images/Call-log/1.webp',
      fullName: 'John Doe',
      role: 'admin',
      about:
        'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
      status: 'online',
      settings: {
        isTwoStepAuthVerificationEnabled: true,
        isNotificationsOn: false
      }
    },
    selectedMessage: {
      id: 1,
      chat: {
        chat: [
          {
            text: 'Do you believe in UFOs?',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: '1',
            senderId: 1,
            img: '/images/facebook/1.jpg',
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Sure, a UFO is just species going from other planets. If you think about it, our space shuttles are UFOs.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'I’ve never thought about like that before.',
            senderId: 1,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I’ve seen photos of UFO in America before, but they’ve all of darked up.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I think there must be.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Why is that?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'I don’t think it will make sense if we are the only being in this whole world. The world is just far to big for that.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          },
          {
            text: 'Do you think there’s intelligent life trying to contact us?',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: true
          },
          {
            text: 'No, but we are trying to contact them.',
            senderId: 11,
            date: 'Mon Dec 10 2018 08:45:00 GMT+0000 (GMT)',
            isFromMe: false
          }
        ],
        contact: { id: 1, fullName: 'Aiden', avatar: '/images/avatars/1.png' }
      }
    },
    list: [
      { id: 1, name: 'Aiden', avatres: '/images/avatars/1.png',chat:'There’s a lot of evidence suggests it will. But I don’t think it’s gonna happen anytime soon.',time:'4:15 PM'},
      { id: 2, name: 'Jacob', avatres: '/images/avatars/2.png',chat:'I hope not. I think it would be a threat to those of us that live in the earth.',time:'4:22 PM' },
      { id: 3, name: 'Ethan', avatres: '/images/avatars/3.png',chat:'English weather is even more predictable than I had previously thought.',time:'4:45 PM' },
      { id: 4, name: 'Caden', avatres: '/images/avatars/4.png',chat:'Ok, I’ll go move the car if you turn the heat on. It’s a bit chilly here.',time:'4:45 PM' },
      { id: 5, name: 'Jackson', avatres: '/images/avatars/5.png',chat:'I fine, too, thank you',time:'4:55 PM' },
      { id: 6, name: 'Smith', avatres: '/images/avatars/6.png',chat:'Didnt I? I doing business studies.',time:'5:01 PM' },
      { id: 7, name: 'Johny', avatres: '/images/avatars/7.png',chat:'well, perhaps you re right.Sometimes EQ matters more than IQ.',time:'5:12 PM' },
      { id: 8, name: 'Sammy', avatres: '/images/avatars/8.png',chat:'I am flying like a bird',time:'5:45 PM' },
      { id: 9, name: 'Cameron', avatres: '/images/avatars/4.png',chat:'Surt wele anytime',time:'4:45 PM' },
      { id: 10, name: 'James', avatres: '/images/avatars/4.png',chat:'Hello..sure',time:'4:45 PM' },
      { id: 11, name: 'Sarah', avatres: '/images/avatars/2.png',chat:'I’m fine, thanks. How are you?',time:'5:45 PM' },
      { id: 12, name: 'Amy', avatres:'/images/avatars/1.png',chat:'Hi, I will be send you the requirements by tomorrow.',time:'5:45 PM' }
    ]
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload
    })
    builder.addCase(fetchChatsContacts.fulfilled, (state, action) => {
      state.contacts = action.payload.contacts
      state.chats = action.payload.chatsContacts
    })
    builder.addCase(selectMessage.fulfilled, (state, action) => {
      state.selectedMessage = action.payload
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
