// ** MUI Imports
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

import { useTranslation } from 'next-i18next'

// ** Components
import Autocomplete from 'src/layouts/components/Autocomplete'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import ShortcutsDropdown from 'src/@core/layouts/components/shared-components/ShortcutsDropdown'
import { useRouter } from 'next/router'
import Link from 'next/link'

// 同步组件
import Synchronize from 'src/@core/components/local-android/synchronize'

const notifications = []

const AppBarContent = props => {
  const { t } = useTranslation('commonAndroid')

  let shortcuts = []

  const ios = [
    {
      url: '/locations/gps',
      title: t('GPS'),
      subtitle: t('historical positioning'),
      icon: 'mdi:locations-outline'
    },

    {
      url: '/whatsapp',
      title: t('WhatsApp'),
      icon: 'ic:baseline-whatsapp',
      subtitle: t('whats app')
    },
    {
      url: '/line',
      title: t('LINE'),
      icon: 'ri:line-line',
      subtitle: t('line')
    },
    {
      url: '/kik',
      title: t('Kik'),
      icon: 'simple-icons:kik',
      subtitle: t('kiik')
    },
    {
      url: '/text-messages',
      title: t('SMS'),
      icon: 'ic:outline-textsms',
      subtitle: t('sms')
    },
    {
      title: t('Photos'),
      url: '/photos',
      subtitle: t('Private photo'),
      icon: 'material-symbols:monochrome-photos'
    },
    {
      title: t('Browser History'),
      url: '/browser-history',
      subtitle: t('browser-history'),
      icon: 'material-symbols:history-rounded'
    },
    {
      title: t('Browser Bookmark'),
      url: '/browser-bookmark',
      subtitle: t('Browser Bookmark'),
      icon: 'material-symbols:bookmark-manager'
    },
    {
      title: t('iCloud Drive'),
      icon: 'ic:baseline-cloud',
      subtitle: t('iCloud Drive'),
      url: '/i-cloud-drive'
    },

    {
      title: t('Calendars'),
      url: '/calendars',
      subtitle: t('Appointments'),
      icon: 'mdi:calendar-month-outline'
    },

    {
      title: t('Reminders'),
      icon: 'ic:round-notifications-none',
      subtitle: t('Reminders'),
      url: '/reminders'
    },
    {
      title: t('Notes'),
      icon: 'material-symbols:speaker-notes-rounded',
      subtitle: t('Notes'),
      url: '/notes'
    },
    {
      title: t('Contacts'),
      icon: 'mdi:contacts',
      subtitle: t('address book'),
      url: '/contacts'
    },

    {
      title: t('APPs'),
      icon: 'material-symbols:speaker-notes-rounded',
      subtitle: t('Apps'),
      url: '/apps'
    },
    {
      title: t('WI-FI'),
      icon: 'material-symbols:speaker-notes-rounded',
      subtitle: t('WI-FI'),
      url: '/wifi-networks'
    }
  ]

  const android = [
    {
      title: t('Browser History'),
      url: '/android/browser-history',
      subtitle: t('browser-history'),
      icon: 'material-symbols:history-rounded'
    },
    {
      title: t('Browser Bookmark'),
      url: '/android/browser-bookmark',
      subtitle: t('Browser Bookmark'),
      icon: 'material-symbols:bookmark-manager'
    },
    {
      url: '/android/text-messages',
      title: t('SMS'),
      icon: 'ic:outline-textsms',
      subtitle: t('sms')
    },
    {
      title: t('Photos'),
      url: '/android/photos',
      subtitle: t('Private photo'),
      icon: 'material-symbols:monochrome-photos'
    },
    {
      title: t('Keep'),
      icon: 'material-symbols:speaker-notes-rounded',
      subtitle: t('Keep'),
      url: '/android/keep'
    },
    {
      title: t('WI-FI'),
      icon: 'material-symbols:speaker-notes-rounded',
      subtitle: t('WI-FI'),
      url: '/android/wifi-networks'
    }
  ]

  if (localStorage.getItem('currentMode') == 'Android-Cloud') {
    shortcuts = android
  } else {
    shortcuts = ios
  }

  const { pathname } = useRouter()

  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden && !settings.navHidden ? (
          <>
            <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
              <Icon icon='mdi:menu' />
            </IconButton>
            {(pathname === '/android/classification' ||
              pathname === '/android/local/setup' ||
              pathname.substring(3) === '/android/classification' ||
              (pathname.substring(3) === '/android/local/setup' &&
                pathname === '/android/step/pricing' &&
                pathname.substring(3) === '/android/step/pricing') ||
              pathname.split('/')[2] === 'step' ||
              pathname.split('/')[3] === 'step') && <p className='font-[600] text-[15px] xs:text-[14px]'>Msafely</p>}
          </>
        ) : null}
        <div style={{ marginLeft: '10px' }}>
          <Synchronize />
        </div>

        <Autocomplete hidden={hidden} settings={settings} />
        {(pathname === '/android/classification' ||
          pathname === '/android/local/setup' ||
          pathname.substring(3) === '/android/classification' ||
          pathname.substring(3) === '/android/local/setup' ||
          pathname === '/android/step/pricing' ||
          pathname.substring(3) === '/android/step/pricing' ||
          pathname.split('/')[2] === 'step' ||
          pathname.split('/')[3] === 'step') && (
          <div className='flex items-center Bxl:hidden'>
            <div className='w-[32px] h-[32px] bg-[#12D8AB] rounded-full flex items-center justify-center'>
              <Icon icon='ic:baseline-settings' className='text-white w-[26px] h-[26px]' />
            </div>
            <p className='font-[700] text-[16px] ml-5 text-[#2B2D33]'>{t('Adding a new device')}</p>
          </div>
        )}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {(pathname === '/android/classification' ||
          pathname === '/android/local/setup' ||
          pathname.substring(3) === '/android/classification' ||
          pathname.substring(3) === '/android/local/setup' ||
          pathname === '/android/step/pricing' ||
          pathname.substring(3) === '/android/step/pricing' ||
          pathname.split('/')[2] === 'step' ||
          pathname.split('/')[3] === 'step') && (
          <Link
            href='/android/step/pricing'
            className=' flex items-center xss:mr-4 bg-[#12D8AB] w-[160px] xs:w-[150px] xs:text-[13px] h-[38px] xs:h-[36px] rounded-[6px] font-[600] text-white text-[14px] justify-center'
          >
            {t('+ ADD NEW DEVICE')}
          </Link>
        )}

        {/* <ShortcutsDropdown settings={settings} shortcuts={shortcuts} /> */}
        <div className='mr-2'>
          <NotificationDropdown settings={settings} notifications={notifications} />
        </div>
        <UserDropdown settings={settings} saveSettings={saveSettings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
