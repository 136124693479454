// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import conversionUtcDate from 'src/utils/conversionUtcDate'

// ** Axios Imports
import axios from 'axios'
import { getSmsChatSummary, getSmsChatDetail } from '/src/@api/dashboards'

// ** Fetch User Profile

// 先获取这边列表数据
let leftPage = 0

export const fetchUserProfile = createAsyncThunk('fetchUserProfile', async () => {
  leftPage += 1

  const response = await getSmsChatSummary({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'SMS_CHAT_SUMMARY',
      offset: (leftPage - 1) * 50,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : ''
    }
  })

  let result = response.data.contents.map(item => {
    return {
      ...item,
      time: conversionUtcDate(item.snippetTimestamp, 'local', 'YYYY-MM-DD'),
      defaultAvata: '/images/avatars/10.png'
    }
  })

  const defaultSelectData = await getSmsChatDetail({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'SMS_CHAT_DETAIL',
      offset: 0,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : '',
      rid: result[0].guid
    }
  })

  let defaultData = []

  if (defaultSelectData.data.contents != null) {
    defaultData = defaultSelectData.data.contents.map(item => {
      return {
        ...item,
        time: conversionUtcDate(item.date, 'local', 'YYYY-MM-DD HH:mm'),
        defaultAvata: '/images/avatars/10.png'
      }
    })
  }

  localStorage.setItem('smsItem', JSON.stringify(result[0]))

  return {
    data: result,
    defaultSelectData: defaultData,
    leftPage: leftPage,
    leftTotalCount: response.data.totalCount
  }
})

// ** Select Message

let rig = ''
let rightPage = 1

export const selectMessage = createAsyncThunk('selectMessage', async (obj, { dispatch }) => {
  let smsItem = JSON.parse(localStorage.getItem('smsItem'))

  if (typeof obj == 'undefined') {
    obj = smsItem
    rightPage = rightPage + 1
  } else {
    rightPage = 1
  }

  rig = obj.guid

  const response = await getSmsChatDetail({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'SMS_CHAT_DETAIL',
      offset: (rightPage - 1) * 50,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : '',
      rid: obj.guid
    }
  })

  let result = []

  if (response.data.contents != null) {
    result = response.data.contents.map(item => {
      return {
        ...item,
        time: conversionUtcDate(item.date, 'local', 'YYYY-MM-DD HH:mm'),
        defaultAvata: '/images/avatars/1.png'
      }
    })
  }

  return { contact: obj, selected: result, rightTotalCount: response.data.totalCount }
})

export const appChatSlice = createSlice({
  name: 'Text message',
  initialState: {
    leftTotalCount: 0,
    rightTotalCount: 0,
    selectedMessage: {
      contact: {},
      selected: []
    },
    list: []
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.list = [...state.list, ...action.payload.data]
      state.leftTotalCount = action.payload.leftTotalCount
      if (action.payload.leftPage == 1) {
        state.selectedMessage = {
          contact: action.payload.data.length > 0 ? action.payload.data[0] : {},
          selected: action.payload.defaultSelectData
        }
      }
    })

    builder.addCase(selectMessage.fulfilled, (state, action) => {
      if (state.selectedMessage.contact.guid == action.payload.contact.guid) {
        state.selectedMessage.selected = [...state.selectedMessage.selected, ...action.payload.selected]
      } else {
        state.selectedMessage.selected = action.payload.selected
      }

      state.selectedMessage.contact = action.payload.contact
      state.rightTotalCount = action.payload.rightTotalCount
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
