// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import conversionUtcDate from 'src/utils/conversionUtcDate'

// ** Axios Imports
import axios from 'axios'
import { getWhatsappChatSummary, getWhatsappChatDetail } from 'src/@api/dashboards'

// ** Fetch User Profile

let leftPage = 0

// 首次的查询详情信息
let firstData = []

export const fetchUserProfile = createAsyncThunk('whatsapp/fetchUserProfile', async () => {
  leftPage += 1

  const response = await getWhatsappChatSummary({
    username:
      JSON.parse(localStorage.getItem('DeviceUser')) != null
        ? JSON.parse(localStorage.getItem('DeviceUser')).username
        : '',
    params: {
      category: 'WHATSAPP_CHAT_SUMMARY',
      offset: (leftPage - 1) * 50,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : ''
    }
  })

  let result = response.data.contents.map(item => {
    return {
      ...item,
      time: conversionUtcDate(item.snippetTimestamp + 1000 * 60 * 60 * 8, 'local', 'YYYY-MM-DD'),
      defaultAvata: '/images/avatars/10.png'
    }
  })

  let defaultData = []

  if (firstData.length <= 0) {
    let defaultSelectData = await getWhatsappChatDetail({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'WHATSAPP_CHAT_DETAIL',
        offset: 0,
        limit: 50,
        deviceUdid:
          JSON.parse(localStorage.getItem('currentDevice')) != null
            ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
            : '',
        rid: String(result[0].PK)
      }
    })

    if (defaultSelectData.data.contents != null) {
      defaultData = defaultSelectData.data.contents.map(item => {
        return {
          ...item,
          time: conversionUtcDate(item.date + 1000 * 60 * 60 * 8, 'local', 'YYYY-MM-DD HH:mm'),
          defaultAvata: '/images/avatars/10.png'
        }
      })

      firstData = [...defaultData]
    }
  } else defaultData = [...firstData]

  localStorage.setItem('whatsappItem', JSON.stringify(result[0]))

  return {
    data: result,
    defaultSelectData: defaultData,
    leftPage: leftPage,
    leftTotalCount: response.data.totalCount
  }
})

// ** Select Message
let PK = ''
let rightPage = 1

export const selectMessage = createAsyncThunk('whatsapp/selectChat', async (obj, { dispatch }) => {
  let whatsappItem = JSON.parse(localStorage.getItem('whatsappItem'))

  if (typeof obj == 'undefined') {
    obj = whatsappItem
    rightPage = rightPage + 1
  } else {
    rightPage = 1
  }

  PK = obj.PK

  let response = await getWhatsappChatDetail({
    username: JSON.parse(localStorage.getItem('DeviceUser')).username || '',
    params: {
      category: 'WHATSAPP_CHAT_DETAIL',
      offset: (rightPage - 1) * 50,
      limit: 50,
      deviceUdid:
        JSON.parse(localStorage.getItem('currentDevice')) != null
          ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
          : '',
      rid: String(PK)
    }
  })

  let result = []

  if (response.data.contents != null) {
    result = response.data.contents.map(item => {
      return {
        ...item,
        time: conversionUtcDate(item.date + 1000 * 60 * 60 * 8, 'local', 'YYYY-MM-DD HH:mm'),
        defaultAvata: '/images/avatars/10.png'
      }
    })
  }

  return { contact: obj, selected: result, rightTotalCount: response.data.totalCount }
})

export const setPerViewImage = createAsyncThunk('whatsapp/setPerView', async (obj, { dispatch }) => {
  return obj
})

export const appChatSlice = createSlice({
  name: 'WhatsApp',
  initialState: {
    leftTotalCount: 0,
    rightTotalCount: 0,
    selectedMessage: {
      contact: {},
      selected: []
    },

    list: [],
    preViewImage: {
      open: false,
      url: ''
    }
  },
  reducers: {
    removeSelectedChat: state => {
      state.selectedChat = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.list = [...state.list, ...action.payload.data]
      state.leftTotalCount = action.payload.leftTotalCount

      if (action.payload.leftPage == 1) {
        state.selectedMessage = { contact: action.payload.data[0], selected: action.payload.defaultSelectData }
      }
    })

    builder.addCase(selectMessage.fulfilled, (state, action) => {
      if (state.selectedMessage.contact.PK == action.payload.contact.PK) {
        state.selectedMessage.selected = [...state.selectedMessage.selected, ...action.payload.selected]
      } else {
        state.selectedMessage.selected = action.payload.selected
      }

      state.selectedMessage.contact = action.payload.contact

      state.rightTotalCount = action.payload.rightTotalCount
    })
    builder.addCase(setPerViewImage.fulfilled, (state, action) => {
      state.preViewImage = action.payload
    })
  }
})

export const { removeSelectedChat } = appChatSlice.actions

export default appChatSlice.reducer
