// ** Next Imports
import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from 'next/image'

// ** MUI Imports
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import { styled, useTheme } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'

// ** Configs Import
import themeConfig from 'src/configs/themeConfig'

// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink'

// ** Util Import
import { handleURLQueries } from 'src/@core/layouts/utils'
import { useEffect, useState } from 'react'

// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  transition: 'padding-left .25s ease-in-out',
  '&.active': {
    '&, &:hover': {
      // backgroundColor: theme.palette.primary.light,
      // backgroundColor: process.env.NEXT_PUBLIC_SIDE_active_color,
      backgroundColor: '#50E3C2',

      // color: '#FFCF55',
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.primary.main
      }
    },

    '& .MuiTypography-root': {
      fontWeight: 500

      // color: `${theme.palette.common.white} !important`
    },
    '& .MuiListItemIcon-root': {
      // color: '#000'
      // color: `${theme.palette.common.white} !important`
    }
  }
}))

const MenuItemTextMetaWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
})

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}) => {
  // ** Hooks
  const theme = useTheme()
  const router = useRouter()

  // ** Vars
  const { mode, navCollapsed } = settings
  const icon = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

  const conditionalIconColor = () => {
    if (mode === 'semi-dark') {
      return {
        color: `rgba(${theme.palette.customColors.dark}, ${parent ? 0.6 : 0.87})`
      }
    } else
      return {
        color: parent ? 'text.secondary' : 'text.primary'
      }
  }

  const conditionalBgColor = () => {
    if (mode === 'semi-dark') {
      return {
        '&:hover': {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.05)`
        }
      }
    } else return {}
  }

  const isNavLinkActive = () => {
    if (router.pathname === item.path || handleURLQueries(router, item.path)) {
      return true
    } else {
      return false
    }
  }

  const [savedAllStatusOn, setSavedAllStatusOn] = useState()
  const [permission, setPermission] = useState(false)

  useEffect(() => {
    // 在组件挂载时从 localStorage 获取数据
    const storedValue = localStorage.getItem('allStatusOn')

    // 将字符串值转换为布尔值
    const booleanValue = JSON.parse(storedValue)
    setSavedAllStatusOn(booleanValue)
    if (typeof item === 'object' && item !== null) {
      // 使用 Object.values 获取对象中的值
      const values = Object.values(item)

      // 使用 includes 方法检查值数组中是否有符合条件的元素
      const hasPermission = values.includes('Permission check')

      // 如果有符合条件的元素，设置 permission 为 true
      if (hasPermission) {
        setPermission(true)
      }
    }
  }, [item])
  const isActive = isNavLinkActive()

  return (
    <CanViewNavLink navLink={item}>
      <ListItem
        disablePadding
        className='nav-link'
        disabled={item.disabled || false}
        sx={{
          mt: '6px',
          transition: 'padding .25s ease-in-out',

          // px: parent ? '0 !important' : `${theme.spacing(navCollapsed && !navHover ? 2 : 3)} !important`
          px: parent ? '0 !important' : '2.5% !important'
        }}

        // style={{ marginTop: '10px' }}
      >
        <MenuNavLink
          component={Link}
          {...(item.disabled && { tabIndex: -1 })}
          className={isNavLinkActive() ? 'active' : ''}
          href={item.path === undefined ? '/' : `${item.path}`}
          {...(item.openInNewTab ? { target: '_blank' } : null)}
          onClick={e => {
            if (item.path === undefined) {
              e.preventDefault()
              e.stopPropagation()
            }
            if (navVisible) {
              toggleNavVisibility()
            }
          }}
          sx={{
            // py: 2.25,
            py: 0,
            height: item.title === 'Dashboard' ? '52px' : '48px',
            mb: item.title === 'Dashboard' ? '10px' : '',

            // ...conditionalBgColor(),
            ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
            px: 0,

            // pr: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 3,
            // pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24 - 16) / 8 : 4,

            // border: '1.5px solid',

            // background: item.title === 'Dashboard' ? 'linear-gradient(to right, #01C2FF, #714FFF)' : '',

            padding: item.title === 'Dashboard' ? '2px' : '',
            borderRadius: item.title === 'Dashboard' ? '8px' : '',

            '&.MuiButtonBase-root': {
              background: item.title === 'Dashboard' ? 'linear-gradient(to right, #01C2C8, #01C300)' : '',
              padding: item.title === 'Dashboard' ? '2px' : ''
            },
            '&.active': {
              background: item.title === 'Dashboard' ? '#50E3C2 !important' : ''
            },
            '&.active .dashboard': { backgroundColor: '#50E3C2 !important' }
          }}
        >
          <div
            className={`${
              item.title === 'Dashboard' ? 'bg-[#06313D] px-[10px]' : 'px-[16px]'
            } w-full h-full rounded-[8px] flex items-center dashboard`}
          >
            {/* {isSubToSub ? null : (
              <ListItemIcon
                sx={{
                  ...conditionalIconColor(),
                  transition: 'margin .25s ease-in-out',
                  ...(navCollapsed && !navHover ? { mr: 0 } : { mr: 2 }),
                  ...(parent ? { ml: 2, mr: 2 } : {}),
                  '& svg': {
                    ...(!parent ? { fontSize: '1.5rem' } : { fontSize: '0.5rem' }),
                    ...(parent && item.icon ? { fontSize: '0.875rem' } : {})
                  }
                }}
              >
                <Box dangerouslySetInnerHTML={{ __html: icon }} />
              </ListItemIcon>
            )} */}
            {icon && (
              <div className={item.title !== 'Dashboard' ? 'mr-[24px]' : 'mr-[15px]'}>
                <Box dangerouslySetInnerHTML={{ __html: icon }} />
              </div>
            )}

            <MenuItemTextMetaWrapper
              sx={{
                ...(isSubToSub ? { ml: 8 } : {}),
                ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 })
              }}
            >
              <Typography
                {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                  noWrap: true
                })}
              >
                <Translations text={item.title} />
              </Typography>
              {item.badgeContent ? (
                <Chip
                  size='small'
                  label={item.badgeContent}
                  color={item.badgeColor || 'primary'}
                  sx={{ ml: 1.5, '& .MuiChip-label': { px: 2.5, lineHeight: 1.385, textTransform: 'capitalize' } }}
                />
              ) : null}

              {/*此处为判断权限是否全部开启，如果全部开启，则不弹出图标提示*/}
              {/* {permission && !savedAllStatusOn && (
                <Image
                  width={16}
                  height={16}
                  src={`/images/android/permission/${isActive ? 'Subtract-1' : 'Subtract'}.svg`}
                  alt='Permission Check Image'
                  className='mr-[6px]'
                />
              )} */}
            </MenuItemTextMetaWrapper>
          </div>
        </MenuNavLink>
      </ListItem>
    </CanViewNavLink>
  )
}

export default VerticalNavLink
