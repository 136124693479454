import { useState, useEffect } from 'react'

import { useTranslation } from 'next-i18next'

// ** MUI Imports
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import MuiListSubheader from '@mui/material/ListSubheader'

// ** Custom Components Imports
import Translations from 'src/layouts/components/Translations'
import CanViewNavSectionTitle from 'src/layouts/components/acl/CanViewNavSectionTitle'

import Icon from 'src/@core/components/icon'

import styles from './VerticalNavSectionTitle.module.scss'
import { messageInfo } from 'src/components/Message'

import { Progress, Space } from 'antd'

// ** Styled Components
const ListSubheader = styled(props => <MuiListSubheader component='li' {...props} />)(({ theme }) => ({
  lineHeight: 1,
  display: 'flex',
  position: 'static',
  padding: theme.spacing(3),
  marginTop: theme.spacing(6.25),
  backgroundColor: 'transparent',
  color: theme.palette.text.disabled,
  transition: 'padding-left .25s ease-in-out'
}))

import { Spin, message } from 'antd'
import {
  authByDevice,
  authVerifyByDevice,
  downloadWeb,
  bySms,
  authVerifyBySms,
  authInfo,
  userBindingDevice,
  checkDownloadTime,
  sendDownloadTime
} from 'src/@api/login'
import { resetIcloud } from 'src/@api/dashboards'
import BasicDownloadForm from './BasicDownloadForm'
import AdvancedDownloadForm from './AdvancedDownloadForm'

// redux
import { useDispatch, useSelector } from 'react-redux'

import { fetchResetTime } from 'src/store/apps/Common'
import Image from 'next/image'

import { getCallHistory } from '/src/@api/dashboards'

const VerticalNavSectionTitle = props => {
  const { t } = useTranslation('common')

  // ** Props
  const { item, navHover, settings, collapsedNavWidth, navigationBorderWidth } = props

  // ** Hook
  const theme = useTheme()
  const dispatch = useDispatch()
  const store = useSelector(state => state.Common)

  // 数据刷新时间
  const { resetTime } = store

  // ** Vars
  const { mode, navCollapsed } = settings

  const conditionalBorderColor = () => {
    if (mode === 'semi-dark') {
      return {
        '&, &:before': {
          borderColor: `rgba(${theme.palette.customColors.dark}, 0.12)`
        }
      }
    } else return {}
  }

  const [messageApi, contextHolder] = message.useMessage()

  const conditionalColor = () => {
    if (mode === 'semi-dark') {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.38) !important`
      }
    } else {
      return {
        color: 'text.disabled'
      }
    }
  }

  const [basicLoading, setBasicLoading] = useState(false)
  const [basicModel, setBasicModel] = useState(false)
  const [basicStep, setBasicStep] = useState(0)

  const [advancedLoading, setAdvancedLoading] = useState(false)
  const [advancedModel, setAdvancedModel] = useState(false)
  const [advancedStep, setAdvancedStep] = useState(0)

  const closeModal = () => {
    setBasicModel(false)
    setAdvancedModel(false)
  }

  // 刷新数据
  const resetData = async type => {
    let check = await getCallHistory({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'CALL_HISTORY',
        offset: 0,
        limit: 4,
        deviceUdid:
          JSON.parse(localStorage.getItem('currentDevice')) != null
            ? JSON.parse(localStorage.getItem('currentDevice'))['Unique Identifier']
            : ''
      }
    })

    // 如果账号过期了,不在执行下面
    if (check.code === 1002) return

    let DeviceUser = JSON.parse(localStorage.getItem('DeviceUser'))
    if (type == 'Basic') {
      if (new Date(store.resetTime.basicUpdateDownloadTime).getTime() + 1000 * 100 > new Date().getTime()) {
        setBasicLoading(true)
        setTimeout(() => {
          setBasicLoading(false)
        }, 6000)

        return
      }

      let obj = {
        mode: 1,
        username: DeviceUser.username,
        password: DeviceUser.password
      }

      // 判断用户名和密码是否为空
      if (obj.username == '' || obj.password == '') {
        setBasicStep(0)
        setBasicModel(true)

        return
      }

      setBasicLoading(true)
      const { data } = await authByDevice(obj)

      // 判断状态true不需要验证 直接下载数据

      if (data.status) {
        setBasicLoading(false)
        let downloadRes = await downloadWeb(obj)
        await sendDownloadTime({ username: DeviceUser.username, type: 'basic_download' })

        messageInfo({
          content: t(
            'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
          ),
          duration: 10
        })

        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 800)

        return
      }

      if (data.ec === 10003) {
        setBasicStep(0)
        setBasicModel(true)
        setBasicLoading(false)
        messageInfo(data.em)
      } else if (data.ec === 10004) {
        setBasicStep(1)
        setBasicModel(true)
        setBasicLoading(false)
        messageInfo(data.em)
      } else if (data.ec === 10005) {
        setBasicStep(0)
        messageInfo(data.em)
        setBasicLoading(false)
      } else if (data.ec === 10000 || data.ec === 0) {
        setBasicLoading(false)
        let downloadRes = await downloadWeb(obj)
        messageInfo({
          content: t(
            'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
          ),
          duration: 10
        })
        await sendDownloadTime({ username: DeviceUser.username, type: 'basic_download' })

        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 800)
      } else {
        messageInfo(data.em)
        setBasicLoading(false)
        await resetIcloud({ mode: 1, username: DeviceUser.username })
      }

      setBasicLoading(false)
    } else {
      if (new Date(store.resetTime.advancedUpdateDownloadTime).getTime() + 1000 * 100 > new Date().getTime()) {
        setAdvancedLoading(true)
        setTimeout(() => {
          setAdvancedLoading(false)
        }, 6000)

        return
      }

      let obj = {
        mode: 2,
        username: DeviceUser.username,
        password: DeviceUser.password
      }

      // 判断用户名和密码是否为空
      if (obj.username == '' || obj.password == '') {
        setAdvancedStep(0)
        setAdvancedModel(true)

        return
      }

      setAdvancedLoading(true)
      const { data } = await authByDevice(obj)

      // 不需要验证直接下载
      if (data.status) {
        setAdvancedLoading(false)

        let downloadRes = await downloadWeb({
          mode: 2,
          username: DeviceUser.username,
          password: DeviceUser.password
        })

        messageInfo({
          content: t(
            'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
          ),
          duration: 10
        })
        await sendDownloadTime({ username: DeviceUser.username, type: 'advanced_download' })

        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 800)

        return
      }

      if (data.ec === 10003) {
        setAdvancedStep(0)
        setAdvancedModel(true)
        setAdvancedLoading(false)
        messageInfo(data.em)
      } else if (data.ec === 10004) {
        setAdvancedStep(1)
        setAdvancedModel(true)
        setAdvancedLoading(false)
        messageInfo(data.em)
      } else if (data.ec === 10000) {
        setAdvancedStep(0)
        setAdvancedLoading(false)
        messageInfo(data.em)
      } else if (data.ec === 10000 || data.ec === 0) {
        setAdvancedLoading(false)

        let downloadRes = await downloadWeb({
          mode: 2,
          username: DeviceUser.username,
          password: DeviceUser.password
        })
        messageInfo({
          content: t(
            'Please wait patiently for the data to download If the data is still not updated after half an hour it is likely that the target device has not enabled backup'
          ),
          duration: 10
        })
        await sendDownloadTime({ username: DeviceUser.username, type: 'advanced_download' })

        setTimeout(() => {
          dispatch(fetchResetTime())
        }, 800)
      } else {
        messageInfo(data.em)
        setAdvancedLoading(false)
        await resetIcloud({ mode: 1, username: DeviceUser.username })
      }
    }
  }

  // 获取设备刷新时间
  useEffect(() => {
    dispatch(fetchResetTime())
  }, [])

  return (
    <CanViewNavSectionTitle navTitle={item}>
      <ListSubheader
        className='nav-section-title'
        sx={{
          ...(navCollapsed && !navHover
            ? { py: 4.75, px: (collapsedNavWidth - navigationBorderWidth - 22) / 8 }
            : { pl: 0 })
        }}
      >
        {navCollapsed && !navHover ? null : (
          <Typography
            noWrap
            variant='caption'
            sx={{ ...conditionalColor(), width: '100%' }}
            style={{ display: 'block', width: '100%', height: '48px', padding: '0 18px', position: 'relative' }}
          >
            <span style={{ fontSize: '20px', textAlign: 'center', float: 'left' }}>{t(item.sectionTitle)}</span>

            <button style={{ float: 'right', cursor: 'pointer', marginTop: '2px' }}>
              {item.sectionTitle == 'Basic' ? (
                <>
                  <div>
                    {basicLoading ? (
                      <Spin />
                    ) : (
                      <>
                        <span className={resetTime.basicUpdateDownloadTime == '' ? styles.resetTime : null}>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <Icon
                            className={resetTime.basicUpdateDownloadTime == '' ? styles.icon : null}
                            icon='ic:round-settings-backup-restore'
                            onClick={() => resetData(item.sectionTitle)}
                            style={{ fontSize: '26px', color: '#fff' }}
                          ></Icon>
                        </span>

                        <span style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                          {resetTime.basicUpdateDownloadTime != ''
                            ? t('Updated') + ':' + resetTime.basicUpdateDownloadTime
                            : t('Please refresh the data')}
                        </span>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div>
                  {advancedLoading ? (
                    <Spin />
                  ) : (
                    <>
                      <span className={resetTime.advancedUpdateDownloadTime == '' ? styles.resetTime : null}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <Icon
                          className={resetTime.advancedUpdateDownloadTime == '' ? styles.icon : null}
                          icon='ic:round-settings-backup-restore'
                          onClick={() => resetData(item.sectionTitle)}
                          style={{ fontSize: '26px', color: '#fff' }}
                        ></Icon>
                      </span>

                      <span style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                        {resetTime.advancedUpdateDownloadTime != ''
                          ? t('Updated') + ':' + resetTime.advancedUpdateDownloadTime
                          : t('Please refresh the data')}
                      </span>
                    </>
                  )}
                </div>
              )}
            </button>
          </Typography>
        )}

        {item.sectionTitle == 'Basic' && basicModel ? (
          <BasicDownloadForm data={basicStep} closeModal={closeModal} />
        ) : null}
        {item.sectionTitle == 'Advanced' && advancedModel ? (
          <AdvancedDownloadForm data={advancedStep} closeModal={closeModal} />
        ) : null}

        {contextHolder}
      </ListSubheader>
      {/* <div>
        <div style={{ padding: '0 20px' }}>
          <Progress
            percent={55}
            trailColor='#F0F0F0'
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
              color: 'red'
            }}
          />
        </div>
      </div> */}
    </CanViewNavSectionTitle>
  )
}

export default VerticalNavSectionTitle
