import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const conversionUtcDate = (date, type, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (type === 'local') {
    // 传入local则将UTC时间转换为本地时间
    return dayjs.utc(date).local().format(format)
  } else if (type === 'UTC') {
    // 传入UTC则将时间转换为UTC时间
    let hour = 0 - new Date().getTimezoneOffset() / 60

    return dayjs(date).add(hour, 'hour').format(format)
  }
}

export default conversionUtcDate
