// ** React Imports
import { useEffect, useState } from 'react'

// ** MUI Imports
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Components
import AppBar from './components/vertical/appBar'
import Customizer from 'src/@core/components/customizer'
import Navigation from './components/vertical/navigation'
import Footer from './components/shared-components/footer'
import ScrollToTop from 'src/@core/components/scroll-to-top'
import TipsBackup from 'src/@core/components/common/tipsBackup'

import styless from './styleVerticalLayout.module.scss'
import { useRouter } from 'next/router'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: 'var(--vh2)*100',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const VerticalLayout = props => {

  const { asPath } = useRouter()

  // ** Props
  const { hidden, settings, children, scrollToTop, footerProps, contentHeightFixed, verticalLayoutProps } = props

  // ** Vars
  const { skin, navHidden, contentWidth } = settings
  const { navigationSize, disableCustomizer, collapsedNavigationSize } = themeConfig
  const navWidth = navigationSize
  const navigationBorderWidth = skin === 'bordered' ? 1 : 0
  const collapsedNavWidth = collapsedNavigationSize

  // ** States
  const [navVisible, setNavVisible] = useState(false)

  // ** Toggle Functions
  const toggleNavVisibility = () => setNavVisible(!navVisible)

  const BasicTips = localStorage.getItem('BasicTips') == 1 && localStorage.getItem('BasicTips') == null ? true : false

  const [AdvancedTips, setAdvancedTips] = useState(false)

  let timer = setInterval(() => {
    let isAdvancedTips =
      (localStorage.getItem('Advanced') == 1 && localStorage.getItem('currentDevice') == null) ||
        (localStorage.getItem('Advanced') == 1 && localStorage.getItem('currentDevice') == '{}')
        ? true
        : false

    if (!isAdvancedTips) {
      setAdvancedTips(isAdvancedTips)
      clearInterval(timer)
    } else {
      setAdvancedTips(isAdvancedTips)
    }
  }, 1000)

  const { pathname } = useRouter()

  useEffect(() => {
    const paddingUrlArr = ['/android/classification', '/android/local/setup']
    const overflowUrlArr = ['/android/local/setup']
    const backgroundArr = ['/android/classification', '/android/local/setup']
    const localArr = ['/', '/fr', '/es', '/de', '/it-ch', '/pt', '/jp', '/kr', '/tw']

    const setupElement = document.getElementsByClassName('localSetup')[0]
    if (paddingUrlArr.some(item => asPath.endsWith(item))) {
      setupElement.style.setProperty('padding', '0px', 'important')
      setupElement.style.setProperty('max-width', '100%', 'important')
    }

    if (overflowUrlArr.some(item => asPath.endsWith(item))) document.body.style.setProperty('overflow', 'hidden', 'important')
    else document.body.style.setProperty('overflow', 'auto', 'important')

    if (backgroundArr.some(item => asPath.endsWith(item)) || asPath.indexOf('accessToken=') !== -1 || localArr.includes(asPath)) {
      setupElement.style.setProperty('background', '#FFFFFF', 'important')
      document.body.style.setProperty('background', '#FFFFFF', 'important')
    } else {
      setupElement.style.setProperty('background', '#F7F7F9', 'important')
      document.body.style.setProperty('background', '#F7F7F9', 'important')
    }
  }, [asPath])

  const [innerWidth, setInnerWidth] = useState(0)

  useEffect(() => {
    const handInnerWidth = () => {
      setInnerWidth(window.innerWidth)
    }

    handInnerWidth()

    window.addEventListener('resize', handInnerWidth)

    return () => {
      window.removeEventListener('resize', handInnerWidth)
    }
  }, [])

  return (
    <div>
      <VerticalLayoutWrapper className='layout-wrapper'>
        {navHidden && !(navHidden && settings.lastLayout === 'horizontal') ? null : (
          <Navigation
            style={{ color: 'red' }}
            navWidth={navWidth}
            navVisible={navVisible}
            setNavVisible={setNavVisible}
            collapsedNavWidth={collapsedNavWidth}
            toggleNavVisibility={toggleNavVisibility}
            navigationBorderWidth={navigationBorderWidth}
            navMenuContent={verticalLayoutProps.navMenu.content}
            navMenuBranding={verticalLayoutProps.navMenu.branding}
            menuLockedIcon={verticalLayoutProps.navMenu.lockedIcon}
            verticalNavItems={verticalLayoutProps.navMenu.navItems}
            navMenuProps={verticalLayoutProps.navMenu.componentProps}
            menuUnlockedIcon={verticalLayoutProps.navMenu.unlockedIcon}
            afterNavMenuContent={verticalLayoutProps.navMenu.afterContent}
            beforeNavMenuContent={verticalLayoutProps.navMenu.beforeContent}
            {...props}
          />
        )}
        <MainContentWrapper
          className={`layout-content-wrapper ${(pathname === '/android/classification' || pathname === '/android/local/setup' || pathname.substring(3) === '/android/classification' || pathname.substring(3) === '/android/local/setup' || pathname === '/android/step/pricing' || pathname.substring(3) === '/android/step/pricing' || pathname.split("/")[2] === 'step' || pathname.split("/")[3] === 'step') && 'bg-white'}`}
          style={{ minHeight: (pathname !== '/android/classification' && pathname !== '/android/local/setup' && pathname.substring(3) !== '/android/classification' && pathname.substring(3) !== '/android/local/setup' && pathname !== '/android/step/pricing' && pathname.substring(3) !== '/android/step/pricing' && pathname.split("/")[2] !== 'step' && pathname.split("/")[3] !== 'step') && 'calc(90vh - 100px)' }}
          sx={{ ...(contentHeightFixed && { maxHeight: 'var(--vh2)*100' }) }}
        >
          <AppBar
            toggleNavVisibility={toggleNavVisibility}
            appBarContent={verticalLayoutProps.appBar?.content}
            appBarProps={verticalLayoutProps.appBar?.componentProps}
            {...props}
          />

          <ContentWrapper
            className={`layout-page-content localSetup ${!asPath.endsWith('/android/classification') ? 'relative' : ''}
               Bxls:Headers5:!p-[40px_45px_45px_45px] Header5:big:!p-[60px_90px_90px_90px] bigs:!p-[90px_120px_120px_120px]
              `}
            sx={{
              flexGrow: '0',
              ...(contentHeightFixed && {
                overflow: 'hidden',
                '& > :first-of-type': { height: '100%' }
              }),
              '@media (max-width:1200px)': {
                paddingLeft: '15px !important',
                paddingRight: '15px !important',
                paddingBottom: '15px !important',
                paddingTop: '12px !important'
              },
              // ...(contentWidth === 'boxed' && {
              //   mx: 'auto',
              //   '@media (min-width:1440px)': { maxWidth: 1440 },
              //   '@media (min-width:1200px)': { maxWidth: '100%' }
              // }),
              '@media (max-width:599.95px)': { p: (pathname.split("/")[3] === 'setup' || pathname.split("/")[4] === 'setup' || pathname.split("/")[3] === 'classification' || pathname.split("/")[2] === 'classification' || pathname.split("/")[2] === 'step') ? '0' : '12px 15px 15px' },
            }}
          >
            <div className='min-h-[calc(var(--vh2)*100-300px)]'>
              <TipsBackup />
              {children}
            </div>
            {/* {
                (pathname !== '/android/classification' && pathname !== '/android/local/setup' && pathname.substring(3) !== '/android/classification' && pathname.substring(3) !== '/android/local/setup' && pathname !== '/android/step/pricing' && pathname.substring(3) !== '/android/step/pricing' && pathname.split("/")[2] !== 'step' && pathname.split("/")[3] !== 'step') &&
                <div
                  style={{
                    marginTop: '50px',
                    borderTop: '1px solid #E2E2E2',
                    padding: '20px 0',
                    textAlign: 'center',
                    color: '#67676F'
                  }}
                >
                  {process.env.NEXT_PUBLIC_COPYRIGHT}
                </div>
              } */}

          </ContentWrapper>

          <Footer footerStyles={footerProps?.sx} footerContent={footerProps?.content} {...props} />
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {disableCustomizer || hidden ? null : <Customizer />}

      {
        scrollToTop ? (
          scrollToTop(props)
        ) : (
          <ScrollToTop className='mui-fixed'>
            <Fab color='primary' size='small' aria-label='scroll back to top'>
              <Icon icon='mdi:arrow-up' />
            </Fab>
          </ScrollToTop>
        )
      }
    </div >
  )
}

export default VerticalLayout
