import { useState, useEffect } from 'react'

import { useTranslation } from 'next-i18next'

// ** MUI Imports
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import MuiListSubheader from '@mui/material/ListSubheader'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// ** Custom Components Imports
import Translations from 'src/layouts/components/Translations'
import CanViewNavSectionTitle from 'src/layouts/components/acl/CanViewNavSectionTitle'

import Icon from 'src/@core/components/icon'

import styles from './VerticalNavSectionTitle.module.scss'

import { Progress, Space } from 'antd'

import { messageInfo } from 'src/components/Message'
import { getNotBindOrder } from 'src/@api/login'

// ** Styled Components
const ListSubheader = styled(props => <MuiListSubheader component='li' {...props} />)(({ theme }) => ({
  lineHeight: 1,
  display: 'flex',
  position: 'static',
  padding: theme.spacing(3),
  marginTop: theme.spacing(6.25),
  backgroundColor: 'transparent',
  color: theme.palette.text.disabled,
  transition: 'padding-left .25s ease-in-out'
}))

import { Spin, message } from 'antd'
import {
  authByDevice,
  authVerifyByDevice,
  downloadWeb,
  bySms,
  authVerifyBySms,
  authInfo,
  userBindingDevice,
  checkDownloadTime,
  sendDownloadTime,
  gcloudDownload,
  gcloudAuth
} from 'src/@api/login'
import { resetIcloud, getSafariHistory } from 'src/@api/dashboards'

// redux
import { useDispatch, useSelector } from 'react-redux'

import { fetchResetTime } from 'src/store/apps/Common'
import Image from 'next/image'
import { getGcloudAll } from '/src/@api/dashboards'
import IconButton from '@mui/material/IconButton'
import Link from 'next/link'
import { useRouter } from 'next/router'

const ExpandMore = styled(props => {
  const { expand, ...other } = props

  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

const VerticalNavSectionTitle = props => {
  const { t } = useTranslation('commonAndroid')

  // ** Props

  // ** Hook
  const theme = useTheme()
  const dispatch = useDispatch()
  const store = useSelector(state => state.Common)

  // 数据刷新时间
  const { resetTime } = store

  // ** Vars

  const [messageApi, contextHolder] = message.useMessage()

  const [basicLoading, setBasicLoading] = useState(false)

  const [basicStep, setBasicStep] = useState(0)
  const [isVerify, setIsVerify] = useState(false)

  const [advancedLoading, setAdvancedLoading] = useState(false)

  // 刷新数据
  const resetData = async () => {
    let check = await getGcloudAll({
      username:
        JSON.parse(localStorage.getItem('DeviceUser')) != null
          ? JSON.parse(localStorage.getItem('DeviceUser')).username
          : '',
      params: {
        category: 'CONTACT',
        offset: 0,
        limit: 1
      }
    })

    // 如果账号过期了,不在执行下面
    if (check.code === 1002) return

    let DeviceUser = JSON.parse(localStorage.getItem('DeviceUser'))

    if (new Date(store.resetTime.basicUpdateDownloadTime).getTime() + 1000 * 100 > new Date().getTime()) {
      setBasicLoading(true)
      setTimeout(() => {
        setBasicLoading(false)
      }, 6000)

      return
    }

    let obj = {
      username: DeviceUser.username,
      password: DeviceUser.password
    }

    // 判断用户名和密码是否为空
    if (obj.username == '' || obj.password == '') {
      setIsVerify(true)
      setBasicStep(0)

      return
    }

    setBasicLoading(true)

    const { data } = await gcloudAuth(obj)

    // 判断状态true不需要验证 直接下载数据

    if (data.status) {
      setBasicLoading(false)
      obj.mode = 4
      let downloadRes = await gcloudDownload(obj)
      await sendDownloadTime({ username: DeviceUser.username, type: 'basic_download' })

      messageInfo({
        content: downloadRes.data.ed,
        duration: 8
      })

      setTimeout(() => {
        dispatch(fetchResetTime())
      }, 800)
    } else {
      setBasicLoading(false)
      setIsVerify(true)
    }
  }

  // 获取设备刷新时间
  useEffect(() => {
    dispatch(fetchResetTime())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 未绑定订单
  const [notOrderBind, setNotOrderBind] = useState(false)

  useEffect(() => {
    const getNotOrderBind = async () => {
      let result = await getNotBindOrder()
      if (result.data.length > 0) {
        setNotOrderBind(true)
      }
    }
    getNotOrderBind()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isOpen, setIsOpen] = useState(false)

  const { pathname } = useRouter()

  return (
    <CanViewNavSectionTitle>
      {/* <ListSubheader
        className='nav-section-title'
        sx={{
          ...(navCollapsed && !navHover
            ? { py: 4.75, px: (collapsedNavWidth - navigationBorderWidth - 22) / 8 }
            : { pl: 0 })
        }}
      >
        {navCollapsed && !navHover ? null : (
          <Typography
            noWrap
            variant='caption'
            sx={{ ...conditionalColor(), width: '100%' }}
            style={{ display: 'block', width: '100%', height: '48px', padding: '0 18px', position: 'relative' }}
          >
            <span style={{ fontSize: '20px', textAlign: 'center', float: 'left' }}>{t(item.sectionTitle)}</span>

            <button style={{ float: 'right', cursor: 'pointer', marginTop: '2px' }}>
              {
                <>
                  <div>
                    {basicLoading ? (
                      <Spin />
                    ) : (
                      <>
                        <span className={resetTime.basicUpdateDownloadTime == '' ? styles.resetTime : null}>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <Icon
                            className={resetTime.basicUpdateDownloadTime == '' ? styles.icon : null}
                            icon='ic:round-settings-backup-restore'
                            onClick={() => resetData()}
                            style={{ fontSize: '26px', color: '#fff' }}
                          ></Icon>
                        </span>

                        <span style={{ position: 'absolute', bottom: '0', right: '20px' }}>
                          {resetTime.basicUpdateDownloadTime != ''
                            ? t('Updated') + ':' + resetTime.basicUpdateDownloadTime
                            : t('Please refresh the data')}
                        </span>
                      </>
                    )}
                  </div>
                </>
              }
            </button>
          </Typography>
        )}
        {isVerify ? <UserVerify /> : null}

        {contextHolder}
      </ListSubheader> */}
      {notOrderBind &&
        (pathname === '/android/classification' ||
          pathname === '/android/local/setup' ||
          pathname.substring(3) === '/android/classification' ||
          pathname.substring(3) === '/android/local/setup' ||
          pathname === '/android/step/pricing' ||
          pathname.substring(3) === '/android/step/pricing' ||
          pathname.split('/')[2] === 'step' ||
          pathname.split('/')[3] === 'step') && (
          <div className='border-t-[1px] border-white/40 py-4'>
            {
              <div className='pl-[28px] pr-[20px]'>
                <div className=''>
                  <div className='text-[#b4c7ce] text-[13px] cursor-pointer outline-none list-none m-0 p-0 text-left'>
                    <div
                      onClick={() => {
                        setIsOpen(!isOpen)
                      }}
                      className='flex items-center justify-between'
                    >
                      <p className='text-[#AAAAAA]'>{t('MY SUBSCRIPTIONS:')}</p>
                      <Image
                        alt='Drop down'
                        src='https://spyx.com/image/Header/13.svg'
                        width={5}
                        height={5}
                        className={`w-[14px] h-[8px] ml-[10px] transform duration-200 ${isOpen && 'rotate-180'}`}
                      ></Image>
                    </div>
                    <Link href={'/android/classification'} className='text-white flex items-center mt-6'>
                      <Image
                        src='/images/android/subtract.svg'
                        alt=''
                        className='w-[24px] h-[24px]'
                        width={5}
                        height={5}
                      ></Image>
                      <p className='text-[#fff] text-[14px] ml-4'>{t('Link device')}</p>
                      {/* <p className='border-[1px] border-[#12D8AB] text-[#12D8AB] text-[10px] rounded-[10px] px-[4px]'>{t('premium')}</p> */}
                    </Link>
                  </div>
                  {isOpen && (
                    <div class='text-left text-[#00EEC3] text-[15px] border-[1px] border-[#12D8AB] h-[35px] rounded-[8px] flex items-center justify-center mt-4'>
                      <Link href='/android/step/pricing'>{t('+ ADD NEW DEVICE')} </Link>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>
        )}
    </CanViewNavSectionTitle>
  )
}

export default VerticalNavSectionTitle
