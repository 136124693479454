// ** Icon Imports
import Icon from 'src/@core/components/icon'
import Image from 'next/image'
import { useState } from 'react'

// ** Third Party Import

import { useTranslation } from 'next-i18next'

import { useRouter } from 'next/router'

import styles from './ModeTogglerCss.module.scss'

// ** Custom Components Imports
import OptionsMenu from 'src/@core/components/option-menu'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

const LanguageDropdown = ({ settings, saveSettings }) => {
  const router = useRouter()

  // ** Hook
  const { i18n } = useTranslation()

  // ** Vars
  const { layout, mode } = settings

  const handleLangItemClick = lang => {
    router.push(
      {
        route: router.pathname,
        query: router.query
      },
      router.asPath,
      { locale: lang }
    )

    i18n.changeLanguage(lang)
  }

  console.log(mode === 'light', 'settings')

  const [isHovered, setIsHovered] = useState(false)
  const { t } = useTranslation('common')

  return (
    <div
      className={`flex items-center py-1 ${styles.active}`}
      style={{ width: '100%' }}
      onMouseEnter={() => setIsHovered('Language')}
      onMouseLeave={() => setIsHovered(false)}
    >
      <OptionsMenu
        className='abcde'
        icon={
          <Box className={`w-[180px] hover:text-[#12D8AB]  flex items-center group `} style={{ width: '100%' }}>
            <Image
              src={isHovered == 'Language' ? '/images/common/lan-active.svg' : '/images/common/lan.svg'}
              width='18'
              height='18'
              alt='img'
              style={{ margin: '0 8px' }}
            />
            <p className={`text-[14px]  font-[600] `}>{t('Language')}</p>
          </Box>
        }
        menuProps={{ sx: { '& .MuiMenu-paper': { mt: 4, minWidth: 130 } } }}
        iconButtonProps={{ color: 'inherit', sx: { ...(layout === 'vertical' ? { mr: 0.75 } : { mx: 0.75 }) } }}
        options={[
          {
            text: 'English',
            icon: <img src='/images/lang/en.svg' alt='img' style={{ marginRight: '4px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'en',
              onClick: () => {
                handleLangItemClick('en')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: '日語',
            icon: <img src='/images/lang/jp.svg' alt='img' style={{ marginRight: '4px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'jp',
              onClick: () => {
                handleLangItemClick('jp')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: 'Deutsch',
            icon: <img src='/images/lang/de.svg' alt='img' style={{ marginRight: '4px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'de',
              onClick: () => {
                handleLangItemClick('de')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: '한국의',
            icon: <img src='/images/lang/kr.svg' alt='img' style={{ marginRight: '4px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'kr',
              onClick: () => {
                handleLangItemClick('kr')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: 'Français',
            icon: <img src='/images/lang/fr.svg' alt='img' style={{ marginRight: '4px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'fr',
              onClick: () => {
                handleLangItemClick('fr')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: 'Español',
            icon: <img src='/images/lang/es.svg' alt='img' style={{ marginRight: '4px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'es',
              onClick: () => {
                handleLangItemClick('es')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: 'Italia',
            icon: <img src='/images/lang/it.svg' alt='img' style={{ marginRight: '4px', width: '12px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'it-ch',
              onClick: () => {
                handleLangItemClick('it-ch')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: 'Portogallo',
            icon: <img src='/images/lang/pt.svg' alt='img' style={{ marginRight: '4px', width: '12px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'pt',
              onClick: () => {
                handleLangItemClick('pt')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: '繁体中文',
            icon: <img src='/images/lang/tw.svg' alt='img' style={{ marginRight: '4px', width: '12px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'tw',
              onClick: () => {
                handleLangItemClick('tw')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: 'Türkçe',
            icon: <img src='/images/lang/tr.svg' alt='img' style={{ marginRight: '4px', width: '12px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'tr',
              onClick: () => {
                handleLangItemClick('tr')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          },
          {
            text: 'العربية',
            icon: <img src='/images/lang/ar.svg' alt='img' style={{ marginRight: '4px', width: '12px' }} />,
            menuItemProps: {
              sx: { py: 2 },
              selected: i18n.language === 'ar',
              onClick: () => {
                handleLangItemClick('ar')
                saveSettings({ ...settings, direction: 'ltr' })
              }
            }
          }

          // {
          //   text: 'French',
          //   menuItemProps: {
          //     sx: { py: 2 },
          //     selected: i18n.language === 'fr',
          //     onClick: () => {
          //       handleLangItemClick('fr')
          //       saveSettings({ ...settings, direction: 'ltr' })
          //     }
          //   }
          // },
          // {
          //   text: 'Arabic',
          //   menuItemProps: {
          //     sx: { py: 2 },
          //     selected: i18n.language === 'ar',
          //     onClick: () => {
          //       handleLangItemClick('ar')
          //       saveSettings({ ...settings, direction: 'rtl' })
          //     }
          //   }
          // }
        ]}
      ></OptionsMenu>
    </div>
  )
}

export default LanguageDropdown
