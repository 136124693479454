function base64ToImage(base64String) {
  const binaryData = atob(base64String)
  const arrayBuffer = new ArrayBuffer(binaryData.length)
  const uint8Array = new Uint8Array(arrayBuffer)

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i)
  }

  const blob = new Blob([uint8Array], { type: 'image/png' })
  const imageUrl = URL.createObjectURL(blob)

  return imageUrl
}

export default base64ToImage
