// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Axios
import axios from 'axios'

// ** Config
import authConfig from 'src/configs/auth'

const bUrl = process.env.NEXT_PUBLIC_BASE_URL
const clientURL = process.env.NEXT_PUBLIC_CLIENT_URL

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

// request
import { getUserInfo, getAllUserDevice, userDeviceCurrent } from '/src/@api/login'

const getParams = url => {
  try {
    url = url.match(/\?([^#]+)/)[1]
    const obj = {}
    const arr = url.split('&')
    for (let i = 0; i < arr.length; i++) {
      let subArr = arr[i].split('=')
      let key = decodeURIComponent(subArr[0])
      let value = decodeURIComponent(subArr[1])
      obj[key] = value
    }

    return obj
  } catch (err) {
    return null
  }
}

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)

  // ** Hooks
  const router = useRouter()
  useEffect(() => {
    const initAuth = async () => {
      const getParams = url => {
        try {
          url = url.match(/\?([^#]+)/)[1]
          const obj = {}
          const arr = url.split('&')
          for (let i = 0; i < arr.length; i++) {
            let subArr = arr[i].split('=')
            let key = decodeURIComponent(subArr[0])
            let value = decodeURIComponent(subArr[1])
            obj[key] = value
          }

          return obj
        } catch (err) {
          return null
        }
      }

      const urlParams = getParams(location.search)

      let accessToken = window.localStorage.getItem(authConfig.storageTokenKeyName)

      if (urlParams != null) {
        // 退出登录
        if (urlParams.accessToken == 'clear') {
          window.localStorage.removeItem('userData')
          window.localStorage.removeItem(authConfig.storageTokenKeyName)
          localStorage.removeItem('Basic')
          localStorage.removeItem('Advanced')
          localStorage.removeItem('DeviceUser')
          localStorage.removeItem('currentDevice', JSON.stringify({}))

          window.location.href = `${clientURL}/login?q=clear`

          return
        }
        if (urlParams.accessToken != accessToken) {
          localStorage.setItem('Basic', 0)
          localStorage.setItem('Advanced', 0)
          localStorage.setItem('DeviceUser', JSON.stringify({}))
          localStorage.setItem('currentDevice', JSON.stringify({}))
        }

        window.localStorage.setItem(authConfig.storageTokenKeyName, urlParams.accessToken)
      } else if (accessToken == null) {
        if (
          window.location.pathname.substring(1) === 'user-guide-ios' ||
          window.location.pathname.substring(1) === 'user-guide-android' ||
          window.location.pathname.substring(4) === 'user-guide-ios' ||
          window.location.pathname.substring(4) === 'user-guide-android' ||
          window.location.pathname.substring(1) === 'back-up-android' ||
          window.location.pathname.substring(1) === 'back-up-ios' ||
          window.location.pathname.substring(4) === 'back-up-ios' ||
          window.location.pathname.substring(4) === 'back-up-android'
        ) {
          location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}/login?guide=${location.href}`

          return
        }
        window.location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}/login`
      }

      let token = window.localStorage.getItem(authConfig.storageTokenKeyName)

      if (token) {
        let user = await getUserInfo()

        let currentMode = ''

        if (urlParams == null) {
          let { all, data } = await userDeviceCurrent()

          if (data != '') {
            if (all.length >= 2 && localStorage.getItem('currentMode') != null) {
              currentMode = localStorage.getItem('currentMode')
            } else {
              currentMode = data
              window.localStorage.setItem('currentMode', data)
            }

            // if (currentMode === 'Android-Local') {
            //   if (router.asPath.split('/')[2] != 'local'&&router.asPath.split('/')[3] != 'checkout'&&router.asPath.split('/')[4] != 'checkout') {
            //     router.push('/android/local/dashboards')
            //   }
            // }

            // if (currentMode === 'Android-Cloud') {
            //   if (router.asPath.split('/')[1] != 'android'&&router.asPath.split('/')[3] != 'checkout'&&router.asPath.split('/')[4] != 'checkout') {
            //     router.push('/android/dashboard')
            //   }
            // }
          }
        }

        let requestMode = currentMode
        if (currentMode.indexOf('Android') >= 0) {
          requestMode = 'Android'
        }

        let allUserDevice = await getAllUserDevice({ platform_type: requestMode })

        if (allUserDevice.data !== null && allUserDevice.data.length > 0) {
          let getCurrentDev = allUserDevice.data.find(row => row.platform_type == currentMode)

          let currentDevice = JSON.parse(localStorage.getItem('currentDevice')) || ''

          console.log(currentMode == 'Android-Local', currentDevice, 45688)
          if (currentMode == 'Android-Local' && currentDevice) {
            let find = allUserDevice.data.find(
              row => row.platform_type == currentMode && row.iCloud_username == currentDevice.id
            )

            if (find) {
              getCurrentDev = find
            }
          }

          if (getCurrentDev) {
            localStorage.setItem(
              'DeviceUser',
              JSON.stringify({
                username: getCurrentDev.iCloud_username,
                password: getCurrentDev.iCloud_password,
                temporary: getCurrentDev.temporary,
                expire_time: getCurrentDev.expire_time
              })
            )
          }

          let getDeviceUser = localStorage.getItem('DeviceUser')

          if (
            JSON.parse(localStorage.getItem('DeviceUser')).username == null ||
            JSON.parse(localStorage.getItem('DeviceUser')).password == null ||
            JSON.parse(localStorage.getItem('DeviceUser')).username == '' ||
            JSON.parse(localStorage.getItem('DeviceUser')).password == ''
          ) {
            localStorage.setItem('Basic', 0)
          } else {
            localStorage.setItem('Basic', 1)
          }

          localStorage.setItem('BasicTips', 1)

          localStorage.setItem('Advanced', 1)

          localStorage.setItem('allDeviceUser', JSON.stringify(allUserDevice.data))
        } else {
          localStorage.setItem('BasicTips', 0)
          localStorage.setItem('Basic', 0)
          localStorage.setItem('Advanced', 0)
          localStorage.setItem('allDeviceUser', JSON.stringify([]))
        }

        user.role = 'admin'

        setLoading(true)
        setLoading(false)
        setUser(user)
        window.localStorage.setItem('userData', JSON.stringify(user))
      } else {
        setLoading(false)
      }
    }

    // var user = { role: 'admin' }

    // setUser(user)

    // setLoading(false)

    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLogin = (params, errorCallback) => {}

  const handleLogout = () => {
    setUser(null)
    window.localStorage.removeItem('userData')
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    localStorage.removeItem('Basic')
    localStorage.removeItem('Advanced')
    localStorage.removeItem('DeviceUser')
    localStorage.removeItem('currentDevice', JSON.stringify({}))

    window.location.href = `${clientURL}/login?q=clear`
  }

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
