import React, { useState, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

const TipsBackup = () => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { query } = useRouter()

  let type = {
    'iOS-Cloud': 'https://my.msafely.com/back-up-ios',
    'Android-Cloud': 'https://my.msafely.com/back-up-android'
  }

  // 检查 currentMode 的值
  const isAndroidLocal = localStorage.getItem('currentMode') === 'Android-Local'

  // 跳转路径
  let href = type[localStorage.getItem('currentMode')] ?? '/'

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [showTips, setShowTips] = useState(false)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let userSummary = JSON.parse(localStorage.getItem('userSummary'))
    try {
      if (userSummary instanceof Array && userSummary.length) {
        let find = userSummary.find(row => row.href == router.asPath)

        if (find && find.num == 0) {
          setShowTips(true)
        } else {
          setShowTips(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [query])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const Bar = useMemo(() => {
    return (
      showTips && (
        <div
          style={{
            borderRadius: '10px',
            color: '#41403E',
            height: 'auto',
            marginBottom: '10px'
          }}
          className='absolute top-[50%] sm:top-[30%] right-[35%] sm:left-[10%] sm:right-[10%] shadow-4xl bg-white md:p-[10px] text-[16px] sm:text-[14px] z-50'
        >
          <div className='p-6 md:p-[50px]'>
            <p className='flex text-center items-center justify-center'>{t('No data')}</p>
            <div className='grid items-center justify-center'>
              {t('Make sure the target phone is backing up')}
              <Link
                target='_blank'
                className='flex text-blue-500 text-center items-center justify-center'
                href={href}
                style={{ textDecoration: 'underline' }}
              >
                {t('Learn how to back up')}
              </Link>
            </div>
          </div>
        </div>
      )
    )
  }, [showTips])

  if (isAndroidLocal) return null

  return <>{Bar}</>
}

export default TipsBackup
