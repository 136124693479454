// ** React Imports
import React, { useState, Fragment, useEffect } from 'react'

import Link from 'next/link'

import { useTranslation } from 'next-i18next'

// ** MUI Imports
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Components
import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Custom Components Imports
import CustomChip from 'src/@core/components/mui/chip'
import CustomAvatar from 'src/@core/components/mui/avatar'

// ** Util Import
import { getInitials } from 'src/@core/utils/get-initials'

import { getNotifications, sendReadNotifications } from 'src/@api/login'
import Image from 'next/image'

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:not(:last-of-type)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  maxHeight: 344
})

// ** Styled Avatar component
const Avatar = styled(CustomAvatar)({
  width: 38,
  height: 38,
  fontSize: '1.125rem'
})

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  flex: '1 1 100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75)
}))

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis'
})

const ScrollWrapper = ({ children, hidden }) => {
  if (hidden) {
    return <Box sx={{ maxHeight: 349, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
  } else {
    return <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
  }
}

const NotificationDropdown = React.memo(
  props => {
    const { t } = useTranslation('common')

    // ** Props
    const { settings, notifications } = props

    // ** States
    const [anchorEl, setAnchorEl] = useState(null)

    // ** Hook
    const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))

    // ** Vars
    const { direction } = settings

    const handleDropdownOpen = event => {
      setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = () => {
      setAnchorEl(null)
    }

    const RenderAvatar = ({ notification }) => {
      const { avatarAlt, avatarImg, avatarIcon, avatarText, avatarColor } = notification

      return <Avatar alt='' src={'/robot.png'} />
    }

    const [notificationData, setNotification] = useState([])
    const [notificationIcon, setNotificationIcon] = useState(false)

    useEffect(() => {
      let notifications = async () => {
        let data = await getNotifications()
        if (data.data.length == 0) {
          setNotificationIcon(false)

          return
        }
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].check == '0') {
            setNotificationIcon(true)
          }
        }

        setNotification(data.data)
      }

      notifications()
    }, [])

    // 已读通知
    const readNotification = async () => {
      for (let i = 0; i < notificationData.length; i++) {
        await sendReadNotifications({ id: notificationData[i].id })
      }
    }

    return (
      <Fragment>
        <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu'>
          <Badge
            color='error'
            variant='dot'
            invisible={!notificationIcon}
            sx={{
              '& .MuiBadge-badge': {
                top: 4,
                right: 4,
                boxShadow: theme => `0 0 0 2px ${theme.palette.background.paper}`
              }
            }}
          >
            <Icon icon='mdi:bell-outline' onClick={readNotification} />
          </Badge>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleDropdownClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        >
          <MenuItem
            disableRipple
            disableTouchRipple
            sx={{ cursor: 'default', userSelect: 'auto', backgroundColor: 'transparent !important' }}
          >
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
              className='p-1'
            >
              <h2 className='font-[700] text-[17px]'>{t('Notifications')}</h2>
              <CustomChip
                skin='light'
                size='small'
                color='primary'
                label={`${notificationData.length} New`}
                sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
              />
            </Box>
          </MenuItem>
          <ScrollWrapper hidden={hidden}>
            {notifications.map((notification, index) => (
              <MenuItem key={index} onClick={handleDropdownClose} className='hover:bg-[#eff1f5]'>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <RenderAvatar notification={notification} />
                  <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                    <MenuItemTitle>{notification.title}</MenuItemTitle>
                    <MenuItemSubtitle variant='body2'>{notification.subtitle}</MenuItemSubtitle>
                  </Box>
                  <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                    {notification.meta}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
            {notificationData.length > 0
              ? notificationData.map(item => (
                  <div
                    key={item.id}
                    style={{ padding: '10px 14px', borderBottom: '1px solid #e6e6f3' }}
                    className='flex items-center '
                  >
                    <div
                      style={{
                        background: '#00BF9A',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Image
                        src={'/images/notiifications/notiifications.png'}
                        width={20}
                        height={20}
                        className='w-[20px] h-[20px] rounded-full'
                        alt=''
                      />
                    </div>

                    <h2 style={{ marginLeft: '16px', width: '300px' }}>{item.content}</h2>
                  </div>
                ))
              : null}
          </ScrollWrapper>
          <MenuItem
            disableRipple
            disableTouchRipple
            sx={{
              py: 3.5,
              borderBottom: 0,
              cursor: 'default',
              userSelect: 'auto',
              backgroundColor: 'transparent !important',
              borderTop: theme => `1px solid ${theme.palette.divider}`
            }}
          >
            <Button fullWidth variant='contained' onClick={handleDropdownClose}>
              <Link href='/notification-list'>{t('READ ALL NOTIFICATIONS')}</Link>
            </Button>
          </MenuItem>
        </Menu>
      </Fragment>
    )
  },
  (prevProps, nextProps) => {
    return false
  }
)

export default NotificationDropdown
