import axios from 'axios'
import Cookies from 'js-cookie'

import { message, Spin } from 'antd'

message.config({
  top: 66,
  duration: 10,
  maxCount: 3,
  rtl: true,
  prefixCls: 'my-message'
})

const bUrl = process.env.NEXT_PUBLIC_CLIENT_URL
const myUrl = process.env.NEXT_PUBLIC_MY_URL
const clientURL = process.env.NEXT_PUBLIC_CLIENT_URL

// 取消重复请求
const CancelToken = axios.CancelToken

// 用于存储每个请求的取消函数以及对应标识
let sources = []

// 取消函数
let removeSource = config => {
  for (let item in sources) {
    if (sources[item].umet === config.url + '&' + config.method) {
      sources[item].cancel('已取消重复请求，请勿重复请求')
    }
  }
}

class AxiosHttpRequest {
  constructor(baseURL, timeout) {
    this.baseURL = baseURL
    this.timeout = timeout
  }

  // 配置参数
  getConfigParams() {
    const config = {
      baseURL: this.baseURL,
      timeout: this.timeout,
      headers: {}
    }

    return config
  }

  // 拦截设置
  interceptors(instance) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        // 取消重复请求
        // removeSource(config)
        // config.cancelToken = new CancelToken(c => {
        //   // 将取消函数存起来
        //   sources.push({ umet: config.url + '&' + config.method, cancel: c })
        // })

        // 判断cookie有没有token没有证明另一个网址退出了
        // if (process.env.NODE_ENV != 'development') {
        //   if (typeof Cookies.get('user_token') == 'undefined') {
        //     window.location.href = `${clientURL}/login?q=clear`
        //   }
        // }

        // 添加全局的loading..

        // 请求头携带token
        let accessToken = localStorage.getItem('accessToken')

        if (accessToken) {
          config.headers['Authorization'] = 'Bucket ' + accessToken
        }
        config.headers['Content-Type'] = 'application/json;charset=utf-8'

        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    // 响应拦截
    instance.interceptors.response.use(
      res => {
        // 取消loading

        // 取消重复请求
        removeSource(res.config)

        // 未设置状态码则默认成功状态
        const code = res.data['code'] || 0

        // 获取错误信息
        // let msg = res.data['msg'] || ''
        // switch (code) {
        //   case 0:
        //     msg = '请求成功'
        //   case '401':
        //     msg = '认证失败，无法访问系统资源'
        //     break
        //   case '403':
        //     msg = '当前操作没有权限'
        //     break
        //   case '404':
        //     msg = '访问资源不存在'
        //     break
        //   case 'default':
        //     msg = '系统未知错误，请反馈给管理员'
        //     break
        //   default:
        //     return '未知错误，请联系管理员'
        // }
        // 未购买

        if (code === 1001) {
          window.location.href = `${bUrl}${process.env.NEXT_PUBLIC_MY_PRICING}`

          return
        }

        // 过期
        if (code === 1002) {
          message.destroy()
          message.warning('device expired！')

          return Promise.resolve(res.data)
        }

        // 未绑定 或者未付款
        if (code === 1003) {
          message.destroy()
          message.warning('Device not bound')

          window.location.href = `${process.env.NEXT_PUBLIC_CLIENT_URL}${process.env.NEXT_PUBLIC_MY_PRICING}`

          return Promise.resolve(res.data)
        }

        // 登录过期
        if (code === 1004) {
          window.location.href = `${clientURL}/login?q=clear`

          return
        }
        if (code === 0 || code === 500 || code) {
          if (typeof res.data.data != 'undefined') {
            res.data.data = res.data.data == null ? {} : res.data.data

            if (typeof res.data.data.resetToken != 'undefined') {
              localStorage.setItem('accessToken', res.data.data.resetToken)
            }
          }

          return Promise.resolve(res.data)
        } else {
          return Promise.reject(res.data)
        }
      },
      error => {
        if (error.response && error.response.status === 429) {
          message.warning(error.response.data.message)

          return Promise.reject({
            code: 0,
            data: {
              totalCount: 0,
              currentCount: 0,
              contents: []
            }
          })
        }
        if (error.message == 'Network Error') {
          error.message = 'Connection abnormality'
        } else if (error.message.includes('timeout')) {
          error.message = 'Request timed out'
        } else if (error.message.includes('Request failed with status code')) {
          error.message = 'system' + error.message.substr(error.message.length - 3) + 'error'
        }

        return Promise.reject(error)
      }
    )
  }

  /**
   * 外部调用方法
   * @param options axios请求参数
   * @returns 实例
   */
  request(options) {
    const instance = axios.create()
    options = Object.assign(this.getConfigParams(), options)
    this.interceptors(instance)

    return instance(options)
  }
  get(url, data) {
    const options = Object.assign(
      {
        method: 'get',
        url: url
      },
      data
    )

    return this.request(options)
  }
  post(url, data) {
    return this.request({
      method: 'post',
      url: url,
      data: data
    })
  }
  put(url, data) {
    return this.request({
      method: 'put',
      url: url,
      data: data
    })
  }
  delete(url, data) {
    const options = Object.assign(
      {
        method: 'delete',
        url: url
      },
      data
    )

    return this.request(options)
  }
}

// 实例化请求类
export default AxiosHttpRequest
